import React, { useContext } from "react"

import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Typography, CircularProgress } from '@mui/material';

import { CenturionContext } from "../../hooks/CenturionContext";
import { ClientSidePagination } from "../../components/ClientSidePagination.jsx";
import { CenturionNoSalesOverlay } from "./CenturionNoSalesOverlay";

import { mobileColumns } from "./format-centurion-columns.js"
import "./CenturionDataGridMobile.css"

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslateKey } from "hooks/useTranslate";
import { formatNumber } from "common/helper-functions";
import { useLanguageContext } from "hooks/LanguageContext";

const CenturionDataGridMobile = ({ sales }) => {
  const { currentMembers, isLoading } = useContext(CenturionContext);
  const totalCenturionCount = useTranslateKey("total_centurion_count")
  const { currentLanguage } = useLanguageContext()

  const theme = useTheme();
  const viewPortIsSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box id="CenturionDataGrid">
      <Box 
        id="CenturionDataGridMobileWrapper"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.paper',
          borderRadius: 1,
          overflow: 'hidden',
          height: viewPortIsSmall ? '874px' : '633.781px',
        }}
      >
        <Box sx={{ padding: "24px 24px 0px 24px" }}>
          <Typography id="sales-header" variant="h3">Centurion Sales</Typography>
        </Box>
        {isLoading && (
          <Box 
            sx={{ 
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center", 
              height: "300px" 
            }}
          >
            <CircularProgress data-test-id="progress-bar-shown" />
          </Box>
        )}
        {!isLoading && currentMembers.length === 0 && (
          <CenturionNoSalesOverlay />
        )}
        {!isLoading && currentMembers.length !== 0 && (
          <Box sx={{ 
            flex: 1,
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}>
            <DataGridPro
              rows={currentMembers}
              disableColumnMenu
              columns={mobileColumns}
              getRowHeight={() => 72}
              pagination
              pageSizeOptions={[10, 25]}
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: 'count', sort: 'desc' }],
                },
                pagination: { paginationModel: { pageSize: viewPortIsSmall ? 25 : 10 } },
              }}
              slots={{
                noRowsOverlay: CenturionNoSalesOverlay,
                noResultsOverlay: CenturionNoSalesOverlay,
                pagination: ClientSidePagination
              }}
              slotProps={{
                pagination: { perPageKey: "sales_per_page" },
              }}
              sx={{
                flex: 1,
                width: '100%',
                border: 'none',
                overflow: 'hidden',
                '& .MuiDataGrid-main': {
                  overflow: 'hidden',
                  flex: 1,
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflow: 'auto !important',
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: '1px solid',
                  borderTopColor: 'divider',
                  borderBottom: 'none'
                },
                '& .MuiDataGrid-root': {
                  border: 'none'
                }
              }}
            />
          </Box>
        )}
        {!isLoading && sales >= 100 && (
          <Box 
            sx={{
              padding: "5px 14px 35px 14px",
              typography: 'caption',
              fontWeight: 600,
              textAlign: "center"
            }}
            mt={2}
          >
            {`${totalCenturionCount}: ${formatNumber(parseInt(sales, currentLanguage))} / 100`}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export { CenturionDataGridMobile }