const LANGUAGE_DETAILS = [
  {
    countryCode: "en-US",
    label: "english",
    i18nCode: "en"
  },
  {
    countryCode: "es-ES",
    label: "español",
    i18nCode: "es"
  },
  {
    countryCode: "de-DE",
    label: "deutsch",
    i18nCode: "de"
  },
  {
    countryCode: "fr-FR",
    label: "français",
    i18nCode: "fr"
  },
  {
    countryCode: "ru-RU",
    label: "pусский",
    i18nCode: "ru"
  },
  {
    countryCode: "ar-EG",
    label: "العربية",
    i18nCode: "ar"
  },
  {
    countryCode: "ja-JP",
    label: "日本語",
    i18nCode: "ja"
  },
  {
    countryCode: "zh-HK",
    label: "粵語",
    i18nCode: "zh-HK"
  },
  {
    countryCode: "zh-CN",
    label: "普通话",
    i18nCode: "zh"
  }
]

function hasCountryCode(array, targetCountryCode) {
  return array.some(item => item.countryCode === targetCountryCode);
}

export { LANGUAGE_DETAILS, hasCountryCode }