import React, { useContext } from "react";
import { T, useTranslateKey } from "../../../hooks/useTranslate";

import { useNavigate, useLocation } from "react-router-dom";
import { useMixPanel } from "../../../hooks/useMixPanel";

import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  Divider,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from "@mui/icons-material/Close";

import { OfficeAvatar } from "../../../components/OfficeAvatar";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
import { DrawerButton } from "../../../components/drawer/DrawerButton";
import { ComButtons } from "../../../components/drawer/ComButtons";
import { MemberErrorScreen } from "../../ErrorScreen/MemberErrorScreen";
import { ProfileReferralLinkLine } from "../../../components/drawer/ProfileReferralLinkLine";

import {
  officeAvatarWrapper,
} from "../../../components/drawer/drawer-styles";
import {
  RANK_DETAILS
} from "../../../common/rankDetails";

import { MemberContext } from "../../../hooks/MemberContext";
import { DistributorContext } from "../../../hooks/DistributorContext";
import { LanguageContext } from "../../../hooks/LanguageContext";
import { useAuth } from "../../../hooks/useAuth";
import { SuccessTracker } from "./SuccessTracker/SuccessTracker";
import { NotesContent } from "../../../components/notes/NotesContent";

import { ProfilePhoneLine } from "../../../components/drawer/ProfilePhoneLine";
import { ProfileEmailLine } from "../../../components/drawer/ProfileEmailLine";
import { ProfileTimezoneLine } from "../../../components/drawer/ProfileTimezoneLine.tsx";
import { ProfileLocationLine } from "../../../components/drawer/ProfileLocationLine";

import { parseProfilePictureUrl } from "../../../common/helper-functions"

const DistributorProfileDrawerContent = ({ isWebView, setIsDrawerOpen, profileViewStack }) => {
  const {
    memberData,
    customerHrefData,
    apiChainInProgress,
    customerOrders,
    errorMessage,
    clearErrorMessage,
    customerNotes,
    setMemberId,
    isDistributorLevelLimit
  } = useContext(MemberContext);
  const { highestRankShort, resetContext } = useContext(DistributorContext);
  const { languageDir } = useContext(LanguageContext);

  const { logEvent } = useMixPanel();

  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useAuth();

  const profilePictureUrl = parseProfilePictureUrl(customerHrefData.profilePicture);

  const rankDetails = RANK_DETAILS.filter(rank => rank.rankCode === highestRankShort)
  const showOrderButtonCount = memberData.type !== 'Associate' || (JSON.parse(localStorage.getItem("selectedMemberContext")))?.type !== 'partner';

  const handleReferrerClick = () => {
    logEvent("open_profile", {
      location: "partner_profile_referred_by",
      user_type: "partner"
    })
    navigate(`${location.pathname}?drawer=partner&id=${customerHrefData.sponsor.id.unicity}&drawerContent=profile`, { state: location });
  };

  // set boolean based on whether the sponsor for a given member is the current user/distributor
  const showReferrer = () => {
    return customerHrefData?.sponsor?.id?.unicity !== userInfo?.unicityId;
  };

  // handle back arrow click
  const handleArrowClick = ({ close }) => {
    resetContext();
    setMemberId("");
    clearErrorMessage();
    setIsDrawerOpen(false);
    // if only one profile is in the stack, navigate to the location path and close drawer
    // otherwise pop the top profile from the stack and navigate next profile in the stack
    if (profileViewStack.size() <= 2 || close === true) {
      navigate(location.pathname, { replace: true });
      profileViewStack.clear();
    } else {
      navigate(`${location.pathname}?drawer=partner&id=${profileViewStack.peekTwo()}&drawerContent=profile`, { state: location.state });
      profileViewStack.pop();
    }
  };

  const translateKey = useTranslateKey;

  const orderButtonText = customerOrders && showOrderButtonCount
    ? `${translateKey("orders")} (${customerOrders.length < 10 ? customerOrders.length : "10+"})`
    : `${translateKey("orders")}`

  // if error in any of the three API calls, render MemberErrorScreen with message
  if (errorMessage) {
    return <MemberErrorScreen />;
  }

  // render loading bar while API chain in in progress
  if (apiChainInProgress)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <CircularProgress data-test-id="progress-bar-shown" />
      </Box>
    );

  return (
    <Box>
      <Box sx={{ padding: "24px" }}>
        <Box sx={{
          display: "flex",
          justifyContent: isWebView ? "flex-end" : "space-between",
          mb: 3
        }}>
          {!isWebView && (languageDir === "ltr" ?
            <KeyboardBackspaceIcon
              onClick={handleArrowClick}
              sx={{ cursor: "pointer" }}
            /> :
            <ArrowForwardIcon
              onClick={handleArrowClick}
              sx={{ cursor: "pointer" }}
            />)}
          <CloseIcon onClick={e => handleArrowClick({ close: true })} sx={{ cursor: "pointer" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Box sx={officeAvatarWrapper}>
            <OfficeAvatar
              id="user-avatar"
              imageUrl={profilePictureUrl}
              height={40}
              width={40}
              firstInitial={customerHrefData?.humanName?.firstName[0]}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              data-test-id="member-profile-name"
              sx={{
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              {customerHrefData?.humanName?.firstName}{" "}
              {customerHrefData?.humanName?.lastName}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: "#7388A1",
                  }}
                >
                  <span data-test-id="member-profile-uid">
                    UID: {customerHrefData?.id?.unicity}{" "}
                  </span>
                  <CopyToClipboard
                    stringToCopy={`${customerHrefData?.id?.unicity}`}
                    iconId="copyUnicityId"
                  />
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: "#7388A1",
                  }}
                >
                  {showReferrer() ? (
                    <Box component={"span"}>
                      <span data-test-id="member-profile-sponsor">{`• Referred by `}</span>
                      <span
                        onClick={handleReferrerClick}
                        style={{ cursor: "pointer" }}
                      >{`${customerHrefData?.sponsor?.humanName?.firstName} ${customerHrefData?.sponsor?.humanName?.lastName}`}</span>
                    </Box>
                  ) : (
                    ""
                  )}
                </Typography>
              </Box>
              {
                // if rank exists in RANK_DETAILS
                rankDetails.length > 0 && (
                  <Stack
                    sx={{
                      backgroundColor:
                        rankDetails[0].backgroundColor,
                      color: rankDetails[0].color,
                      borderRadius: "38px",
                      padding: "4px 8px 4px 8px",
                      marginRight: "16px"
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "10px",
                      }}
                    >
                      <T>{rankDetails[0].rankTranslateFullKey}</T>
                    </Typography>
                  </Stack>
                )
              }
            </Box>

          </Box>
        </Box>
        <ComButtons
          customerHrefData={customerHrefData}
          drawerType="partner"
          eventLocation={"partner_profile"}
        />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: ".4fr 4fr",
            rowGap: 2,
            p: 2,
          }}
        >
          {/* phone section */}
          <ProfilePhoneLine
            customerHrefData={customerHrefData}
            drawerType="partner"
            eventLocation="partner_profile"
          />
          {/* email section */}
          <ProfileEmailLine
            customerHrefData={customerHrefData}
            drawerType="partner"
            eventLocation="partner_profile"
          />
          <ProfileReferralLinkLine
            customerHrefData={customerHrefData}
            referralCode={memberData?.referralCode}
            drawerType="partner"
            eventLocation="partner_profile"
          />
          {/* location section */}
          <ProfileLocationLine customerHrefData={customerHrefData} drawerType="distributor" />
          {/* timezone section - hidden until can show current time in location */}
          <ProfileTimezoneLine customerHrefData={customerHrefData} drawerType="distributor" />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }} mt={2}>
          <DrawerButton
            id="distributors-profile-order-button"
            text={orderButtonText}
            isDisabled={customerOrders?.data?.items?.length === 0}
            type="orders"
          />
          <DrawerButton
            id="distributors-profile-referral-button"
            text={<T>see_frontline</T>}
            showFrontlineIcon={true}
            isDisabled={
              (location.state ? location?.state?.totalPartners <= 0 : false) || isDistributorLevelLimit
            }
          />
        </Box>
      </Box>
      <Divider />
      <SuccessTracker />
      <Divider />
      <NotesContent
        customerNotes={customerNotes}
        customerId={memberData.unicity}
        location="partner_profile"
      />
    </Box>
  );
};

export { DistributorProfileDrawerContent };
