import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import isEqual from "lodash/isEqual";
import { faker } from "@faker-js/faker";
import * as Yup from "yup";
import { displayNameCombinedBlacklist, displayNameIncludesBlacklist } from "../../../components/LoginComponents/blacklists";

import { T } from "../../../hooks/useTranslate";
import { useAuth } from "../../../hooks/useAuth";
import { useApi } from "../../../hooks/useApi";

import {
  Stack,
  Typography,
  Divider,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { OfficeBox } from "../../../components/OfficeBox";
import { SettingsProfilePhotoSection } from "./SettingsProfilePhotoSection";
import { SettingsDisplayNameSection } from "./SettingsDisplayNameSection";
import { BackdropLoadingOverlay } from "../../../components/BackdropLoadingOverlay";
import { ResetFormButton } from "./ResetFormButton";
import { SaveChangesButton } from "./SaveChangesButton";
import styles from "../settingsScreenStyles";

const SettingsDistributorProfile = ({ showLoadingOverlay, setShowLoadingOverlay }) => {
  const { userInfo, setUserInfo } = useAuth();
  const {
    sendRequest: pictureSendRequest,
    sendRequest: displayNameSendRequest,
  } = useApi();

  const [displayNameFromApi, setDisplayNameFromApi] = useState({
    firstName: "",
    lastName: "",
  });
  const [displayNameInputValue, setDisplayNameInputValue] = useState({
    firstName: "",
    lastName: "",
  });

  const [profilePictureUrl, setProfilePictureUrl] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(null);
  const [apiError, setApiError] = useState("");

  // state variable needed as depends on both formik errors and changed input
  const [enableSaveChanges, setEnableSaveChanges] = useState(false);
  const [enableReset, setEnableReset] = useState(false);

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));

  const handleClose = () => {
    setShowLoadingOverlay(false);
  };
  const handleOpen = () => {
    setShowLoadingOverlay(true);
  };

  const handleError = (ex) => {
    setApiError(ex.message);
  };

  // create validation schema for first name and last name
  const createNameValidation = (fieldName) => {
    return Yup.string()
      .required(<T param1={"2"}>name_at_least</T>)
      .min(2, <T param1={"2"}>name_at_least</T>)
      .max(20, <T param1={"20"}>name_at_most</T>)
      .matches(/^[\p{L}\p{M}\s\-.]*$/u, <T>name_invalid_characters</T>) // Allow Unicode, spaces, hyphens, and periods
      .lowercase()
      // test combination of first name and last name against blacklist by sorting each array and doing a comparison
      .test("fullname-blacklist", <T>full_name_restricted</T>, (value) => {
        const otherFieldValue = fieldName === 'firstName' ? formik.values.lastName : formik.values.firstName;
        const fullName = [`${value.toLowerCase().replace(/\s/g, "")}`, `${otherFieldValue.toLowerCase().replace(/\s/g, "")}`].sort();

        // loop through blacklist and sort each array and do a comparison
        for (const idx in displayNameCombinedBlacklist) {
          const blacklistedArray = displayNameCombinedBlacklist[idx].sort();
          if (isEqual(fullName, blacklistedArray)) {
            return false;
          }
        }
        return true;
      })
      .test("fieldName-includes", <T>name_restricted</T>, (value) => {
        return !displayNameIncludesBlacklist.some((item) => value.toLowerCase().replace(/\s/g, "").includes(item.toLowerCase().replace(/\s/g, "")));
      });
  };

  const validationSchema = Yup.object({
    firstName: createNameValidation('firstName'),
    lastName: createNameValidation('lastName'),
  });

  // formik is only being used for validation schema
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: validationSchema,
    validateOnMount: false,
  });

  // on initial render, fetch display name
  useEffect(() => {
    fetchDisplayName();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Check if there are changes in display name or profile picture
    const hasChanges =
      !isEqual(displayNameFromApi, displayNameInputValue) ||
      // eslint-disable-next-line
      profilePictureUrl != userInfo.profilePictureUrl;

    // Check if there are validation errors and form is dirty to enable save changes button
    const validationErrorsExist = Object.keys(formik.errors).length > 0 && formik.dirty;

    setEnableSaveChanges(hasChanges && !validationErrorsExist);
    setEnableReset(hasChanges);
    // eslint-disable-next-line
  }, [
    displayNameFromApi,
    displayNameInputValue,
    profilePictureUrl,
    userInfo.profilePictureUrl,
    formik,
  ]);

  // get initial profilePictureUrl
  useEffect(() => {
    if (userInfo.profilePictureUrl) {
      setProfilePictureUrl(userInfo.profilePictureUrl);
    }
  }, [userInfo]);

  const fetchDisplayName = async () => {
    try {
      handleOpen();
      const res = await displayNameSendRequest({
        method: "GET",
        endpoint: `customers/me/customerSite`,
      });
      setDisplayNameFromApi(res?.data?.content?.humanName);
      setDisplayNameInputValue(res?.data?.content?.humanName);
    } catch (err) {
      console.error(err);
    } finally {
      handleClose();
    }
  };

  const makeUpdateProfilePicturePromise = () => {
    return pictureSendRequest({
      method: "POST",
      endpoint: `customers/me/profilePicture`,
      data: {
        media: selectedFile
      },
      addContentTypeMedia: true,
    })
      .then((res) => {
        const newProfileHref = res.data.sizes[0].media;
        const updatedUserInfo = {
          ...userInfo,
          profilePictureUrl: newProfileHref,
        };
        setUserInfo(updatedUserInfo);
        localStorage.setItem("user", JSON.stringify(updatedUserInfo));
        setProfilePictureUrl(newProfileHref);
        setSelectedFile(null);
      })
      .catch(handleError);
  };

  const makeRemoveProfilePicturePromise = () => {
    return pictureSendRequest({
      method: "DELETE",
      endpoint: `customers/me/profilePicture`,
    })
      .then((res) => {
        const updatedUserInfo = {
          ...userInfo,
          profilePictureUrl: null,
        };
        setUserInfo(updatedUserInfo);
        localStorage.setItem("user", JSON.stringify(updatedUserInfo));
        setProfilePictureUrl(null);
        setSelectedFile(null);
      })
      .catch(handleError);
  };

  const makeUpdateDisplayNamePromise = () => {
    const displayNameInputValueTrimmed = {};

    for (const key in displayNameInputValue) {
      if (displayNameInputValue.hasOwnProperty(key)) {
        displayNameInputValueTrimmed[key] = displayNameInputValue[key].trim();
      }
    }
    const data = {
      alias: faker.string.uuid(),
      content: {
        humanName: displayNameInputValueTrimmed,
      },
    };
    return displayNameSendRequest({
      method: "POST",
      endpoint: `customers/me/customerSite`,
      addContentType: true,
      data: data,
    })
      .then(() => {
        setDisplayNameFromApi(displayNameInputValueTrimmed);
      })
      .catch(handleError);
  };

  const handleSaveChanges = async () => {
    handleOpen();
    setApiError("");

    const promises = [];

    // Test to find out which promises we should make
    const updateProfilePicture =
      profilePictureUrl !== userInfo.profilePictureUrl && selectedFile;

    const removeProfilePicture = !profilePictureUrl;

    const updateDisplayName = !isEqual(
      displayNameFromApi,
      displayNameInputValue
    );

    // Add promises to the promises array
    if (updateProfilePicture) promises.push(makeUpdateProfilePicturePromise());

    if (removeProfilePicture) promises.push(makeRemoveProfilePicturePromise());

    if (updateDisplayName) promises.push(makeUpdateDisplayNamePromise());

    // Use Promise.allSettled to avoid fast fail on any rejected promise
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
    Promise.allSettled(promises)
      .then((results) => {
        results.forEach((result, i) => {
          if (result.status === "rejected") {
            console.error(`Promise ${i + 1} failed:`, result.reason);
          }
        });
      })
      .finally(() => {
        handleClose();
      });
  };

  // Reset profile picture, display name, and formik values for Discard Changes button
  const handleFormReset = () => {
    setDisplayNameInputValue(displayNameFromApi);
    setProfilePictureUrl(userInfo.profilePictureUrl);
    setSelectedFile(null);
    setApiError("");

    formik.setValues({
      firstName: displayNameFromApi.firstName,
      lastName: displayNameFromApi.lastName,
    });

    formik.setErrors({});
  };

  return (
    <OfficeBox sx={styles.mainContent}>
      <Stack data-test-id="distributor-profile-header" sx={styles.header}>
        <Typography variant="h2">
          <T>distributor_profile</T>
        </Typography>
      </Stack>
      <SettingsProfilePhotoSection
        profilePictureUrl={profilePictureUrl}
        setProfilePictureUrl={setProfilePictureUrl}
        setSelectedFile={setSelectedFile}
        data-test-id="profile-photo-section"
      />
      <Divider />
      <SettingsDisplayNameSection
        formik={formik}
        displayNameInputValue={displayNameInputValue}
        setDisplayNameInputValue={setDisplayNameInputValue}
        displayNameFromApi={displayNameFromApi}
        setDisplayNameFromApi={setDisplayNameFromApi}
        handleSaveChanges={handleSaveChanges}
        enableSaveChanges={enableSaveChanges}
        showLoadingOverlay={showLoadingOverlay}
        viewportIsMedium={viewportIsMedium}
      />
      <Divider />
      <Stack
        direction={viewportIsSmall ? "row" : "column-reverse"}
        justifyContent="flex-end"
        alignItems={viewportIsSmall ? "center" : "normal"}
        gap={2}
        mt={3}
        data-test-id="save-button-section"
      >
        {enableReset && (
          <ResetFormButton styles={styles} handleFormReset={handleFormReset} />
        )}
        <SaveChangesButton
          styles={styles}
          handleSaveChanges={handleSaveChanges}
          enableSaveChanges={enableSaveChanges}
        />
      </Stack>
      {apiError && (
        <Stack
          direction={viewportIsSmall ? "row" : "column-reverse"}
          justifyContent="flex-end"
          alignItems={viewportIsSmall ? "center" : "normal"}
          gap={2}
          mt={3}
          data-test-id="save-button-section"
        >
          <Alert severity="error"><T>error_5006</T></Alert>
        </Stack>
      )}
      <BackdropLoadingOverlay showLoadingOverlay={showLoadingOverlay} handleClose={handleClose} />
    </OfficeBox>
  );
};

export { SettingsDistributorProfile };
