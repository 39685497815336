const staticCountries = [
  { country: "Algeria", code: "DZ", alpha3: "DZA" },
  // { country: "Anguilla", code: "AI" },
  { country: "Australia", code: "AU", alpha3: "AUS" },
  { country: "Austria", code: "AT", alpha3: "AUT" },
  { country: "Bahamas", code: "BS", alpha3: "BHS" },
  // { country: "Barbados", code: "BB" },
  { country: "Belgium", code: "BE", alpha3: "BEL" },
  // { country: "Bonaire", code: "BQ" },
  { country: "Canada", code: "CA", alpha3: "CAN" },
  { country: "Colombia", code: "CO", alpha3: "COL" },
  // { country: "Curaçao", code: "CW" },
  { country: "Denmark", code: "DK", alpha3: "DNK" },
  // { country: "Dominica", code: "DM" },
  { country: "Dominican Republic", code: "DO", alpha3: "DOM" },
  { country: "Egypt", code: "EG", alpha3: "EGY" },
  { country: "France", code: "FR", alpha3: "FRA" },
  { country: "Germany", code: "DE", alpha3: "DEU" },
  // { country: "Grenada", code: "GD" },
  { country: "Hungary", code: "HU", alpha3: "HUN" },
  { country: "India", code: "IN", alpha3: "IND" },
  { country: "Ireland", code: "IE", alpha3: "IRL" },
  { country: "Italy", code: "IT", alpha3: "ITA" },
  // { country: "Jamaica", code: "JM" },
  { country: "Jordan", code: "JO", alpha3: "JOR" },
  { country: "Kenya", code: "KE", alpha3: "KEN" },
  { country: "Lebanon", code: "LB", alpha3: "LBN" },
  { country: "Mexico", code: "MX", alpha3: "MEX" },
  { country: "Morocco", code: "MA", alpha3: "MAR" },
  { country: "Netherlands", code: "NL", alpha3: "NLD" },
  { country: "New Zealand", code: "NZ", alpha3: "NZL" },
  { country: "Norway", code: "NO", alpha3: "NOR" },
  { country: "Oman", code: "OM", alpha3: "OMN" },
  { country: "Poland", code: "PL", alpha3: "POL" },
  { country: "Puerto Rico", code: "PR", alpha3: "PRI" },
  { country: "Saudi Arabia", code: "SA", alpha3: "SAU" },
  { country: "Spain", code: "ES", alpha3: "ESP" },
  { country: "Sweden", code: "SE", alpha3: "SWE" },
  { country: "Switzerland", code: "CH", alpha3: "CHE" },
  // { country: "Trinidad and Tobago", code: "TT" },
  { country: "Turkey", code: "TR", alpha3: "TUR" },
  // { country: "Turks and Caicos Islands", code: "TC" },
  // { country: "US Virgin Islands", code: "VI" },
  { country: "Ukraine", code: "UA", alpha3: "UKR" },
  { country: "United Arab Emirates", code: "AE", alpha3: "UAE" },
  { country: "United Kingdom", code: "GB", alpha3: "GBR" },
  { country: "United States", code: "US", alpha3: "USA" },
  // { country: "Indonesia", code: "ID", alpha3: "IDN" },
  { country: "Singapore", code: "SG", alpha3: "SGP" },
  { country: "Malaysia", code: "MY", alpha3: "MYS" },
  { country: "Brunei", code: "BN", alpha3: "BRN" },
  { country: "Philippines", code: "PH", alpha3: "PHL" },
  { country: "Taiwan", code: "TW", alpha3: "TWN" },
  { country: "Hong Kong", code: "HK", alpha3: "HKG" }
];

export { staticCountries }