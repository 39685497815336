import { useContext, useState, useEffect } from "react";
import { Box, Link, Stack, Tooltip, Typography, ClickAwayListener } from "@mui/material";
import { PartnersContext } from "../../../hooks/PartnersContext";

import { useNavigate, useLocation } from "react-router-dom";

import { useMixPanel } from '../../../hooks/useMixPanel';

import { OfficeAvatar } from "../../../components/OfficeAvatar";
import { PartnerNameContent } from "./PartnerNameContent";
import { ChipContainer, BottomChips, TopChips } from "./ChipContainer";

import { parseProfilePictureUrl } from "../../../common/helper-functions"

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getDaysBeforeEndOfTheMonth, today } from '../../../common/date-functions';
import { T } from "../../../hooks/useTranslate";
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useParams } from "react-router-dom";


const PartnersNameCell = ({ params: member, customerType, dataGridParams }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();
  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const { leg1UnicityId, leg2UnicityId, selectedMonth } = useContext(PartnersContext);
  const isSearchResultsPage = location.pathname.includes("search-results");
  const profilePictureUrl = parseProfilePictureUrl(member.profilePicture);
  const cellWidth = dataGridParams.colDef.computedWidth;
  const { distributorId } = useParams();
  const viewingMyFrontline = distributorId === undefined;

  const apiRef = useGridApiContext();

  const [showToolTip, setShowToolTip] = useState(false);
  const [showTVPVwarning, setShowTVPVwarning] = useState(false);
  const [requirement, setRequirement] = useState("TV and PV");

  useState(() => {
    const isPVrequiremensMet = member.currentRank > 1;
    const isTVrequirementsMet = member.uncompressedTv >= 1000;

    if (!(!isPVrequiremensMet && !isTVrequirementsMet)) {
      setRequirement(isPVrequiremensMet ? "TV" : "PV");
    }

    setShowTVPVwarning(!isPVrequiremensMet || !isTVrequirementsMet);
  })

  const remainingDaysText = {
    7: "Seven(7)",
    6: "Six(6)",
    5: "Five(5)",
    4: "Four(4)",
    3: "Three(3)",
    2: "Two(2)",
    1: "One(1)",
  }

  useEffect(() => {
    const handleGridScroll = () => {
      if (showToolTip) {
        setShowToolTip(false);
      }
    };
    apiRef.current.subscribeEvent('scrollPositionChange', handleGridScroll);
  }, [apiRef, showToolTip]);

  // mirror logic in the ChipContainer to determine the gap for the name cell
  const nameCellHasChip =
    customerType === "member" ||
    member.customerStatus === "Suspended" ||
    (customerType === "partner" && (member.customerStatus !== "Suspended" && (member.currentRank < 2 && member.previousPaidRank < 2))) ||
    (customerType === "member" && (member.customerStatus !== "Suspended" && (member.currentRank < 2 && member.previousPaidRank < 2))) ||
    member?.unicityId?.trim() === leg1UnicityId?.trim() ||
    member?.unicityId?.trim() === leg2UnicityId?.trim()
  const calculatedGap = nameCellHasChip ? (viewportIsLarge ? "" : "2px") : "2px";

  const showBottomChipSeparatorDot = customerType === "member" ||
    member.customerStatus === "Suspended" ||
    (customerType === "partner" && (member.customerStatus !== "Suspended" && (member.currentRank < 2 && member.previousPaidRank < 2)) ) ||
    (customerType === "member" && (member.customerStatus !== "Suspended" && (member.currentRank < 2 && member.previousPaidRank < 2)) )

  const handleNameClick = () => {
    localStorage.removeItem("selectedMemberContext");
    localStorage.setItem("selectedMemberContext", JSON.stringify(member));
    logEvent("open_profile", {
      location: isSearchResultsPage ? "partners_search_results" : "partners_page",
      user_type: "partner"
    })
    navigate(`${location.pathname}${location.search ? location.search : "?"}&drawer=partner&id=${member.unicityId}&drawerContent=profile`, {
      state: {
        location: location,
        userFirstName: member.firstName,
        userFullName: member.name,
        totalPartners: member.totalPartners,
      },
    });
  };

  const openTooltip = () => {
    setShowToolTip(true);
  };

  const closeTooltip = () => {
    if (showToolTip) {
      setShowToolTip(false);
    }
  };

  return (
    <Box
      className="flex-row"
      data-test-id="name-cell-container"
      data-field="name-cell-container"
      gap={1}
      sx={{ opacity: member.customerStatus === "Suspended" && ".6" }}
    >
      <Box>
        <OfficeAvatar
          imageUrl={profilePictureUrl}
          firstInitial={member.name[0]?.toUpperCase()}
          id="first-letter"
        />
      </Box>
      <Box className="flex-column" style={{ alignItems: "baseline", gap: calculatedGap }}>
        <Stack direction="row" alignItems="center" gap={.5}>
          {
            customerType === "member" ? (
              <PartnerNameContent member={member} cellWidth={cellWidth} />
            ) : (
              <Stack direction="row" spacing={1}>
                <Link
                  sx={{ fontSize: "12px" }}
                  component="button"
                  underline="hover"
                  href="#"
                  onClick={handleNameClick}
                >
                  <PartnerNameContent member={member} cellWidth={cellWidth} />
                </Link>
              </Stack>
            )
          }
          {viewingMyFrontline && today.day >= 25 && today.toFormat('yyyy-MM') === selectedMonth && showTVPVwarning && (
            viewportIsLarge ?
              (<Tooltip
                placement={"right"}
                disableFocusListener
                disableTouchListener
                arrow
                title={
                  <Box sx={{ display: "flex", padding: "14px 14px 14px 14px" }}>
                    <Typography
                      sx={{
                        "maxWidth": "200px",
                        "fontFamily": "Inter",
                        "fontSize": "11px",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "120%",
                      }} align="center">
                      <T param1={remainingDaysText[getDaysBeforeEndOfTheMonth()]} param2={requirement} >{"tv_pv_requirements_not_met"}</T>
                    </Typography>
                  </Box>
                }
              >
                <img src="icons/alert-icon.svg" alt="option" />
              </Tooltip>) :
              (<ClickAwayListener onClickAway={closeTooltip}>
                <Tooltip
                  placement={"top"}
                  open={showToolTip}
                  disableHoverListener
                  arrow
                  title={
                    <Box sx={{ display: "flex", padding: "14px 14px 14px 14px" }}>
                      <Typography
                        sx={{
                          "maxWidth": "200px",
                          "fontFamily": "Inter",
                          "fontSize": "11px",
                          "fontStyle": "normal",
                          "fontWeight": "400",
                          "lineHeight": "120%",
                        }} align="center">
                        <T param1={remainingDaysText[getDaysBeforeEndOfTheMonth()]} param2={requirement}>{"tv_pv_requirements_not_met"}</T>
                      </Typography>
                    </Box>
                  }
                >
                  <Box onClick={openTooltip}>
                    <img src="icons/alert-icon.svg" alt="option" />
                  </Box>
                </Tooltip>
              </ClickAwayListener>)
          )}
          {
            viewportIsLarge && nameCellHasChip && (
              <ChipContainer
                viewportIsLarge={viewportIsLarge}
                customerType={customerType}
                member={member}
                leg1UnicityId={leg1UnicityId}
                leg2UnicityId={leg2UnicityId}
              />
            )
          }
          {
            !viewportIsLarge && nameCellHasChip && (
              <TopChips
                leg1UnicityId={leg1UnicityId}
                leg2UnicityId={leg2UnicityId}
                member={member}
              />
            )
          }
        </Stack >
        <Stack
          direction={!viewportIsLarge ? "row" : "column"}
          data-field="unicity-id"
          data-test-id="unicity-id"
        >
          <span style={{
            fontSize: "10px",
            fontWeight: 400,
            opacity: "60%",
            alignItems: "flex-start"
          }}>
            {member.unicityId}
            {!viewportIsLarge && showBottomChipSeparatorDot && <span style={{ marginLeft: '2px' }}>{"•"}</span>}
          </span>
          {
            !viewportIsLarge && nameCellHasChip && (
              <Stack direction="row" gap={.5}>
                <BottomChips member={member} customerType={customerType} />
              </Stack>
            )
          }
        </Stack>
      </Box >
    </Box >
  );
};

export { PartnersNameCell };
