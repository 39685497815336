import React, { useContext } from "react";

import { Box, MenuItem, FormControl, Select, IconButton } from "@mui/material";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { ActionHubContext } from "../../../contexts/ActionHubContext"
import { LanguageContext } from "../../../hooks/LanguageContext";
import { RowPageCount } from "../../../components/RowPageCount";

const ActionHubPaginationMobile = ({ rowCount }) => {
  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    loading
  } = useContext(ActionHubContext);

  const { languageDir } = useContext(LanguageContext);

  const iconStyling = {
    border: "1px solid rgba(145, 158, 171, 0.2);",
    borderRadius: "4px",
    padding: 0,
    marginRight: "8px",
    transform: languageDir === "rtl" ? "rotate(180deg)" : "none",
  };

  // change pageSize
  const handleSelectDropdown = (e) => {
    setPage(0);
    setPageSize(e.target.value);
  };

  // handle action button clicks
  const handleNavClick = (e) => {
    switch (e.currentTarget.name) {
      case "first-page-icon":
        setPage(0);
        break;
      case "nav-before-icon":
        setPage(page - 1);
        break;
      case "nav-next-icon":
        setPage(page + 1);
        break;
      case "last-page-icon":
        rowCount % pageSize === 0
          ? setPage(Math.floor(rowCount / pageSize - 1))
          : setPage(Math.floor(rowCount / pageSize));
        break;
      default:
        break;
    }
  };

  const highestRowOnPage = (page + 1) * pageSize > rowCount ? rowCount : (page + 1) * pageSize;

  return (
    <Box
      id="pagination-container"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 4px 8px 4px",
      }}
    >
      <Box sx={{ fontSize: "12px" }}>
        <RowPageCount
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          highestRowOnPage={highestRowOnPage}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{ marginRight: "24px", display: "flex", alignItems: "center" }}
        >
          <FormControl size="small">
            <Select
              labelId="page-size-select-label"
              id="page-size-select"
              aria-label="change page size"
              value={pageSize}
              onChange={handleSelectDropdown}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              disabled={loading || rowCount === 0}
            >
              <MenuItem value={10} aria-label="select 10">10</MenuItem>
              <MenuItem value={50} aria-label="select 50">50</MenuItem>
              <MenuItem value={100} aria-label="select 100">100</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label="go to first page"
            id="first-page-button"
            onClick={handleNavClick}
            sx={iconStyling}
            disabled={loading || page === 0}
            name="first-page-icon"
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            aria-label="go to page before"
            id="before-page-button"
            onClick={handleNavClick}
            sx={iconStyling}
            disabled={loading || page === 0}
            name="nav-before-icon"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            aria-label="go to next page"
            id="next-page-button"
            onClick={handleNavClick}
            sx={iconStyling}
            disabled={loading || highestRowOnPage === rowCount}
            name="nav-next-icon"
          >
            <NavigateNextIcon />
          </IconButton>
          <IconButton
            aria-label="go to last page"
            id="last-page-button"
            onClick={handleNavClick}
            sx={iconStyling}
            disabled={loading || highestRowOnPage === rowCount}
            name="last-page-icon"
          >
            <LastPageIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export { ActionHubPaginationMobile };
