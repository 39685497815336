import React from "react"

import { useNavigate, useLocation } from "react-router-dom";

import { Box, Link } from "@mui/material"
import { OfficeAvatar } from "../../components/OfficeAvatar";

import { formatInitial } from "../../common/format-functions";

const CenturionMemberCell = ({ params }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleArrowClick = () => {
    localStorage.removeItem("selectedMemberContext")
    localStorage.setItem("selectedMemberContext", JSON.stringify(params));
    navigate(`${location.pathname}?drawer=member&id=${params.unicityId}&drawerContent=profile`, { state: location });
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px" }}>
      <Box>
        <OfficeAvatar firstInitial={formatInitial(params.name)} addBadge={true} badgeOn={params.count !== 0} id="user-avatar" />
      </Box>
      <Link
        onClick={handleArrowClick}
        sx={{
          fontSize: "12px",
          fontWeight: "600",
          opacity: params.count !== 0 ? "" : "70%",
          cursor: "pointer",
          textDecoration: "none"
        }}>{params.name}
      </Link>

    </Box>
  )
}

export { CenturionMemberCell }
