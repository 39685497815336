import React from "react";
import { FormControlLabel, Radio, Typography } from "@mui/material";
import { toTitleCase } from "../../common/format-functions";
import { FormikProps } from "formik";

interface LanguageModalOptionProps {
  value: string;
  label: string;
  formik: FormikProps<{
    selection: string;
  }>;
}

const LanguageModalOption: React.FC<LanguageModalOptionProps> = ({ value, label, formik }) => {
  return (
    <FormControlLabel
      data-test-id={`${value}-radio-option`}
      value={value}
      control={<Radio />}
      label={
        <Typography
          sx={{
            maxWidth: "150px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {toTitleCase(label)}
        </Typography>
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue("selection", e.target.value)}
      sx={{
        "& .MuiTypography-root": {
          fontSize: "14px",
          opacity: formik.values.selection !== value ? "0.8" : "1",
          color: "rgba(21, 56, 98, 1)",
        },
        "& .MuiRadio-root": {
          color: "rgba(21, 56, 98, 0.4)",
        },
        "& .MuiRadio-root.Mui-checked": {
          color: "rgba(0, 158, 207, 1)",
        },
      }}
    />
  );
};

export { LanguageModalOption }; 