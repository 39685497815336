import { T } from "../../hooks/useTranslate";
import React, { useState, useEffect } from "react";
import Flag from "react-world-flags";

import {
  Typography,
  Stack,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Box,
  ListSubheader,
  Button,
  CircularProgress
} from "@mui/material";

import { staticCountries } from "../../common/countries";
import { CopyToClipboard } from "../../components/CopyToClipboard";

import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import { useConfig } from "../../hooks/useConfig";
import { ReferralLinksAlert } from "../../components/ReferralLinksAlert";
import { useMixPanel } from "../../hooks/useMixPanel";
import { referralLinkConfig } from "common/referralLinkConfig";
import { transformProductData } from "common/helper-functions";
import { fgMarketOptions } from "common/fg-market-options";
import { fgMarkets, fgMarketsWithNoLanguageSelect, excludedCountry } from "common/marketExceptions";
import { chineseCountries } from "common/marketExceptions";

const DropdownLoader = ({ testId }) => (
  <Stack
    ml={5}
    sx={{
      justifyItems: 'center',
      alignItems: 'center',
    }}
    data-test-id="c_dropdown_loader_container"
  >
    <CircularProgress
      sx={{
        marginTop: "25px",
        marginRight: "33px"
      }}
      data-test-id={testId || "c_dropdown_loader"}
    />
  </Stack>
)

const CustomLinkSection = () => {
  const { logEvent } = useMixPanel();

  const [baseUrl, setBaseUrl] = useState("");
  const [url, setUrl] = useState("");

  const [countryObj, setCountryObj] = useState({});

  const [websiteOptions, setWebsiteOptions] = useState([]);
  const [website, setWebsite] = useState("");

  const [pageOptions, setPageOptions] = useState([]);
  const [pageName, setPageName] = useState("");
  const [pageDetails, setPageDetails] = useState({});

  const [languageOptions, setLanguageOptions] = useState([]);
  const [language, setLanguage] = useState("");
  const [isLongLink, setIsLongLink] = useState(true)
  const [isLinkLoading, setIsLinkLoading] = useState(false)
  const [link, setLink] = useState("")

  const [productOptions, setProductOptions] = useState([]);
  const [product, setProduct] = useState("");
  const [productImage, setProductImage] = useState("")
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [isProductImageLoading, setIsProductImageLoading] = useState(true);

  const { sendRequest } = useApi();
  const { userInfo } = useAuth();
  const { data: configData } = useConfig("countryLists/memberMarketsWhoNeedUSProducts.json");
  const [country, setCountry] = useState(userInfo?.countryCode);
  const [isWebsiteDropdownLoading, setIsWebsiteDropdownLoading] = useState(false)
  const [isProductDropdownLoading, setIsProductDropdownLoading] = useState(false)
  const [hidePageSelect, setHidePageSelect] = useState(false)
  // on load, if countryCode exists in userInfo, set country using that value, otherwise fetch countryCode based on user IP
  useEffect(() => {
    if (userInfo.countryCode) {
      setCountry(userInfo.countryCode);
    } else {
      fetch("http://ip-api.com/json/?fields=countryCode", {
        method: "GET",
        redirect: "follow",
        headers: {
          "x-application": `office.web`
        }
      })
        .then((response) => response.text())
        .then((result) => setCountry(JSON.parse(result).countryCode))
        .catch((error) => console.log("error", error));
    }
    // remove any local storage key that ends in -catalogSlides to avoid "Failed to execute 'setItem' on 'Storage': Setting the value of '{country}-catalogSlides' exceeded the quota." error which tells us that the local storage is full
    // This commit on November 28, 2023 removed any use of local storage (https://github.com/Unicity/office.react/commit/6662dc0a32b2e20760567db2a46b5edba9ab6239)
    // This local storage clean up can be removed in the future if we are still certain there are no catalog slides being stored in local storage
    Object.keys(localStorage).forEach((key) => {
      if (key.includes("-catalogSlides")) {
        localStorage.removeItem(key);
      }
    });
    // eslint-disable-next-line
  }, [userInfo.countryCode]);

  // get country object from staticCountries.js to convert two character code to three to build URLs
  // fetch possible languages from cdn
  useEffect(() => {
    const filtered = staticCountries.filter((x) => {
      return x.code === country;
    });
    setCountryObj(filtered[0]);

    // hit CDN to get country info that includes languages for dropdown
    fetch("https://cdn.unicity.com/api/countries.json", {
      method: "GET",
      redirect: "follow"
    })
      .then((response) => response.text())
      .then((result) => {
        setLanguageOptions(
          JSON.parse(result).items.filter((x) => x.alpha2 === country)[0]
            .languages
        );
        const currentDefaultLangArray = JSON.parse(result)
          .items.filter((x) => x.alpha2 === country)[0]
          .languages.filter((lang) => lang.default === true);
        if (currentDefaultLangArray.length === 1) {
          setLanguage(currentDefaultLangArray[0].code);
        }
      })
      .catch((error) => console.log("error", error));
  }, [country]);

  // hit drupal API to get drawers based on country, then set state of dropdown options using response
  useEffect(() => {
    setPageName("");
    setIsWebsiteDropdownLoading(true)
    if (fgMarkets.includes(country)) {
      setWebsiteOptions(fgMarketOptions.website)
      setIsWebsiteDropdownLoading(false)
      return
    }
    sendRequest({
      method: "get",
      endpoint: `drawers/${country}%20Office%20Referral`,
    })
      .then((res) => {
        setWebsiteOptions(res.data.childDrawers);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setIsWebsiteDropdownLoading(false)
      })

    // eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    if (!website) return
    setHidePageSelect(false)
    const filtered = websiteOptions.filter((x) => x.alias === website);
    if (fgMarkets.includes(country)) {
      if (filtered[0].alias === "Feel Great Referral Links") {
        setHidePageSelect(true)
        setBaseUrl(filtered[0].pages[0].url)
        return
      }
      setPageOptions(filtered[0].pages)
      return
    }
    setPageOptions(filtered.length > 0 ? filtered[0].childDrawers[0].cards : []);
  }, [website, websiteOptions, country])

  const getCountryName = (country) => {
    if (country === "hong kong") return "hongkong";
    if (country === "brunei") return "singapore";
    return country;
  };

  const generateFGMarketReferralLink = (country) => {
    const distId = userInfo.unicityId;
    const selectedCountryInfo = staticCountries.find((c) => c.code === country)
    const countryName = getCountryName(selectedCountryInfo?.country.toLowerCase());
    if (baseUrl?.indexOf("ushop") !== -1 && pageName === "uShop") {
      setUrl(fgMarketsWithNoLanguageSelect.includes(country) && !chineseCountries.includes(country)
        ? `${baseUrl}/${countryName}/s/cat/all/${distId}?lang=en` 
        : `${baseUrl}/${countryName}/s/cat/all/${distId}?lang=${chineseCountries.includes(country) ? "zh" : language}`
      )
    } else if (baseUrl?.indexOf("ushop-link") !== -1 && pageName === "uEnroll") {
      setUrl(fgMarketsWithNoLanguageSelect.includes(country) && !chineseCountries.includes(country)
        ? `${baseUrl}/${countryName}/enroll/${distId}?lang=en` 
        : `${baseUrl}/${countryName}/enroll/${distId}?lang=${chineseCountries.includes(country) ? "zh" : language}`
      )
    } else if (baseUrl?.indexOf("ufeelgreat") !== -1 && website === "Feel Great Referral Links") {
      setUrl(fgMarketsWithNoLanguageSelect.includes(country) && !chineseCountries.includes(country)
        ? `${baseUrl}/c/${distId}?lang=en` 
        : `${baseUrl}/c/${distId}?lang=${chineseCountries.includes(country) ? "zh" : language}`
      )
    }
  }

  useEffect(() => {
    // build URL based on dropdowns, for reference to logic see office.ng referral-links.js:313
    if (!baseUrl) return
    if (fgMarkets.includes(country)) {
      generateFGMarketReferralLink(country)
      return
    }
    if (pageName && language && country) {
      const showRefId =
        pageDetails.meta.length > 0
          ? pageDetails?.meta?.filter((k) => k.key === "ref_id")[0].value
          : null;
      const distId = userInfo.unicityId;
      const needsUSProducts = configData?.data?.memberMarketsWhoNeedUSProducts.includes(country)
        ? "US"
        : country;

      // handle enrollment
      if (baseUrl.indexOf("enroll") !== -1) {
        // use hard-coded URL for Dominican Republic enrollment page based on feedback from Colby
        // trim is neccessary because the pageName has a trailing space
        if (country === "DO" && language === "es" && pageName.trim() === "Unicity Enrollment") {
          console.log("Use hard-coded URL for Dominican Republic enrollment page...", `https://enrollment.unicity.com/dom/es/home/${distId}`);
          setUrl(`https://enrollment.unicity.com/dom/es/home/${distId}`);
        } else {
          let path1 = `/${distId}/${language}_${needsUSProducts}`;
          let path2 = `/${language}_${needsUSProducts}`;
          setUrl(
            `${baseUrl}/${needsUSProducts.toLowerCase()}${showRefId ? path1 : path2
            }`
          );
        }

      }
      // handle activate, unimate, matcha, stayfit
      else if (
        baseUrl.indexOf("activate") !== -1 ||
        baseUrl.indexOf("unimate") !== -1 ||
        baseUrl.indexOf("matcha") !== -1 ||
        baseUrl.indexOf("stayfit") !== -1
      ) {
        setUrl(
          `${baseUrl}&locale=${language}-${needsUSProducts}&refId=${distId}&ctype=M`
        );
        // handle membership
      } else if (baseUrl.indexOf("membership") !== -1) {
        setUrl(
          `${baseUrl}/${needsUSProducts.toLowerCase()}/${distId}/${language.toLowerCase()}_${needsUSProducts.toUpperCase()}`
        );
        // handle feelgreat
      } else if (baseUrl.indexOf("feelgreat") !== -1) {
        if (baseUrl.includes("products")) {
          setUrl(
            `${baseUrl.replace(
              "products",
              ""
            )}${countryObj.alpha3.toLowerCase()}/${language}/p/unimate-select/c/${distId}`
          );
          // handle ushop
        } else if (baseUrl.includes("ushop")) {
          const countryName = countryObj.country
            .split(" ")
            .join("")
            .toLowerCase();
          setUrl(`${baseUrl}/${countryName}/${distId}`);
        } else {
          setUrl(
            `${baseUrl}/${countryObj.alpha3.toLowerCase()}/${language}/c/${distId}`
          );
        }
      } else {
        // handle getfit21 and shop and A1C Test Kit
        const urlLower = baseUrl.toLowerCase();
        if (urlLower.indexOf("getfit21") !== -1) {
          const referralId = showRefId ? `?refId=${distId}` : "";
          setUrl(
            `${urlLower}/loc/${language}-${needsUSProducts}${referralId}&ctype=M`
          );
        } else if (urlLower.includes("mylabsdirect.com")) {
          setUrl(urlLower);
        } else {
          //shop
          let replaced = urlLower.replace("#/home", "#");
          if (replaced.indexOf("all-products") !== -1) {
            setUrl(
              `${replaced.replace(
                "#/all-products",
                "#"
              )}/redirect?to=allProducts&locale=${language}-${needsUSProducts}&refId=${distId}&ctype=M`
            );
          } else {
            setUrl(
              `${replaced}/redirect?to=home&locale=${language}-${needsUSProducts}&refId=${distId}&ctype=M`
            );
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [
    pageName,
    baseUrl,
    country,
    language,
    pageDetails.meta,
    userInfo.unicityId,
    countryObj,
    website,
    configData?.data?.memberMarketsWhoNeedUSProducts
  ]);

  // if product changes, override URL and send to shop
  useEffect(() => {
    if (product !== "") {
      const sku = productOptions.filter((p) => p.productName === product)[0]
        ?.sku;
      setUrl(
        `https://shop.unicity.com/#/redirect?sku=${sku}&locale=${language ? language : "en"
        }-${country}&refId=${userInfo.unicityId}&ctype=M`
      );
    }
  }, [product, productOptions, country, language, userInfo]);

  useEffect(() => {
    const fetchProductOptions = async () => {
      if (pageName !== "Unicity Shop") return;

      setIsProductDropdownLoading(true);

      try {
        const selectedCountry = country === "BN" ? "SG" : country;
        const countryConfig = referralLinkConfig[selectedCountry.toLowerCase()];
        const alpha3 = staticCountries.find((c) => c.code === selectedCountry)?.alpha3;

        let response;

        switch (countryConfig) {
          case 2:
            response = await sendRequest({
              method: "get",
              endpoint: `${process.env.REACT_APP_USHOP_URL}/products-v2/publish/${alpha3}?allow=shop&status=C&warehouse=Main`,
              addPrefix: false,
              addToken: false,
              addXApplicationHeader: false
            });
            setProductOptions(transformProductData(response.data.items, 2));
            break;

          case 3:
            response = await sendRequest({
              method: "get",
              endpoint: `catalogs/${country}%20Shopping?expand=catalogSlides.item`,
            });
            setProductOptions(transformProductData(response.data.catalogSlides, 3));
            break;

          default:
            response = await sendRequest({
              method: "get",
              endpoint: `https://solar.unicity.com/product-catalog/published/list/shop2/${alpha3}`,
              addPrefix: false,
              addToken: false,
              addXApplicationHeader: false
            });
            setProductOptions(transformProductData(response.data.items, 1));
        }

      } catch (error) {
        console.error('Error fetching product options:', error.message);
      } finally {
        setIsProductDropdownLoading(false);
      }
    };

    fetchProductOptions();
    // eslint-disable-next-line
  }, [pageName, country]);

  // when country changes, clear all other values
  const handleCountryChange = (event) => {
    setPageName("");
    setWebsite("");
    setUrl("");
    setIsLongLink(true)
    setProduct("");
    setIsWebsiteDropdownLoading(true)
    setCountry(event.target.value);
  };

  // when website changes, clear fields other than country
  const handleWebsiteChange = (event) => {
    setPageName("");
    setUrl("");
    setIsLongLink(true)
    setProduct("");
    setWebsite(event.target.value);
  };

  const handlePageChange = (event) => {
    // when page is selected, set page name (used to filter page options, set page details, set baseURL)
    setPageName(event.target.value);
    setUrl("");
    setIsLongLink(true)
    setProductImage("")
    setProduct("");
    const selectedPage = pageOptions.filter((p) => p.name === event.target.value)[0];
    setPageDetails(selectedPage);

    setBaseUrl(
      fgMarkets.includes(country)
        ? selectedPage.url
        : pageOptions.filter((p) => p.name === event.target.value)[0].links[0].url
    );
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleProductChange = (event) => {
    setUrl("");
    setIsLongLink(true);
    setProductImage("");
    setIsProductImageLoading(true);
    const product = productOptions.filter((p) => p.productName === event.target.value)[0];
    setProduct(product.productName);
    setProductImage(product?.imageUrl);
  };

  const toggleLink = () => {
    logEvent({
      eventName: 'referral_link_switch',
    },
      {
        location: 'custom_link_section',
        from: isLongLink ? 'long_link' : 'short_link',
        to: isLongLink ? 'short_link' : 'long_link'
      });
    setIsLinkLoading(true)
    if (isLongLink) {
      setIsLongLink(false)
      if (url) {
        sendRequest({
          method: "post",
          endpoint: `https://unicity.link/create`,
          addPrefix: false,
          data: { url: url },
          addContentType: true,
        })
          .then((res) => {
            link !== "" && setShowUpdateAlert(true);
            setLink(`https://unicity.link/${res.data.shortLink}`);
          })
          .catch((error) => {
            link !== "" && setShowUpdateAlert(true);
            setLink(url);
          })
          .finally(() => setIsLinkLoading(false))
        return
      }
    }
    setLink(url || "")
    setIsLinkLoading(false)
    setIsLongLink(true)
  }

  useEffect(() => setLink(url), [url])

  useEffect(() => {
    console.log({ url})
  }, [url])

  return (
    <Stack id="custom-link-section" data-test-id="c_custom_link_section">
      <Typography variant="h3" data-test-id="c_custom_link_title">
        <T>custom_link</T>
      </Typography>

      {/* country input */}
      <FormControl fullWidth sx={{ marginTop: "36px", maxWidth: "500px" }} data-test-id="c_country_form">
        <InputLabel id="country-label" data-test-id="c_country_label">
          <T>country</T>
        </InputLabel>
        <Select
          labelId="country-label"
          id="country-select"
          value={country}
          label="Country"
          onChange={handleCountryChange}
          sx={{ height: "48px" }}
          MenuProps={{
            PaperProps: { sx: paperPropsObj },
          }}
          data-test-id="c_country_select"
        >
          <ListSubheader
            sx={{
              fontWeight: "600",
              fontSize: "10px",
              opacity: ".5",
              marginTop: "8px",
              lineHeight: "16px",
            }}
            disableSticky
          >
            <T>countries</T>
          </ListSubheader>
          {staticCountries.sort((a, b) => a.country.localeCompare(b.country)).map((country, idx) => {
            return (
              !excludedCountry.includes(country.code) &&
              <MenuItem
                key={idx}
                value={country.code}
                data-test-id={`${country.code}`}
              >
                <Stack direction="row">
                  <Flag code={country.code} height="24" width="24" />
                  <Typography variant="h8" sx={{ marginLeft: "10px" }}>
                    {country.country}
                  </Typography>
                </Stack>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {/* website input */}
      {
        (isWebsiteDropdownLoading)
          ? (
            <DropdownLoader testId="c_website_dropdown_loader" />
          )
          : (
            <FormControl fullWidth sx={{ marginTop: "24px", maxWidth: "500px" }} data-test-id="c_website_form">
              <InputLabel id="website-label" sx={{ fontSize: "14px" }}>
                <T>website</T>
              </InputLabel>
              <Select
                labelId="website-label"
                id="website-select"
                value={website}
                label="Website"
                onChange={handleWebsiteChange}
                sx={{ height: "48px" }}
                MenuProps={{
                  PaperProps: { sx: paperPropsObj },
                }}
              >
                <ListSubheader
                  sx={{
                    fontWeight: "600",
                    fontSize: "10px",
                    opacity: ".5",
                    marginTop: "8px",
                    lineHeight: "16px",
                  }}
                  disableSticky
                >
                  <T>websites</T>
                </ListSubheader>
                {websiteOptions.map((website, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      value={website.alias}
                      data-test-id={`${website.alias}`}
                    >
                      <Typography variant="h8">{website.alias}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )
      }
      {/* isPageAndLanguageLoading */}
      {(website !== "" || product !== "") && (
        <Box>
          {
            !hidePageSelect && (
              <>
                <Typography
                  mt={1}
                  pl={2}
                  pr={2}
                  sx={{ fontSize: "12px", fontWeight: "400", opacity: ".6" }}
                >
                  <T>website_subtext</T>
                </Typography>
                {/* page input */}
                <FormControl fullWidth sx={{ marginTop: "24px", maxWidth: "500px" }}>
                  <InputLabel id="page-label" sx={{ fontSize: "14px" }}>
                    <T>page</T>
                  </InputLabel>
                  <Select
                    labelId="page-label"
                    id="page-select"
                    value={pageName}
                    label="Page"
                    onChange={handlePageChange}
                    sx={{ height: "48px" }}
                    MenuProps={{
                      PaperProps: { sx: paperPropsObj },
                    }}
                  >
                    <ListSubheader
                      sx={{
                        fontWeight: "600",
                        fontSize: "10px",
                        opacity: ".5",
                        marginTop: "8px",
                        lineHeight: "16px",
                      }}
                      disableSticky
                    >
                      <T>pages</T>
                    </ListSubheader>
                    {pageOptions.map((page, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          value={page.name}
                          data-test-id={`${page.name}`}
                        >
                          <Typography variant="h8">{page.name}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )
          }

          {/* page language input */}
          {
            !fgMarketsWithNoLanguageSelect.includes(country) && (
              <FormControl fullWidth sx={{ marginTop: "24px", maxWidth: "500px" }}>
                <InputLabel id="page-language-label" sx={{ fontSize: "14px" }}>
                  <T>language</T>
                </InputLabel>
                <Select
                  labelId="page-language-label"
                  id="page-language-select"
                  value={language}
                  label="Language"
                  onChange={handleLanguageChange}
                  sx={{ height: "48px" }}
                  MenuProps={{
                    PaperProps: { sx: paperPropsObj },
                  }}
                >
                  <ListSubheader
                    sx={{
                      fontWeight: "600",
                      fontSize: "10px",
                      opacity: ".5",
                      marginTop: "8px",
                      lineHeight: "16px",
                    }}
                    disableSticky
                  >
                    <T>language</T>
                  </ListSubheader>
                  {languageOptions.map((language, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        value={language.code}
                        data-test-id={`${language.code}`}
                      >
                        <Typography variant="h8">{language.name}</Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )
          }

          {/* Products Select Dropdown */}
          {productOptions &&
            !fgMarkets.includes(country) &&
            website !== "Feel Great Referral Links" &&
            pageName === "Unicity Shop" && (
              (isProductDropdownLoading)
                ? (<DropdownLoader testId="c_product_dropdown_loader" />)
                : (
                  <FormControl
                    fullWidth
                    sx={{ marginTop: "24px", maxWidth: "500px" }}
                  >
                    <InputLabel id="page-language-label" sx={{ fontSize: "14px" }}>
                      <T>products</T>
                    </InputLabel>
                    <Select
                      labelId="products-label"
                      id="products-select"
                      value={product}
                      label="products"
                      onChange={handleProductChange}
                      sx={{ height: "48px" }}
                      MenuProps={{
                        PaperProps: { sx: paperPropsObj },
                      }}
                    >
                      <ListSubheader
                        sx={{
                          fontWeight: "600",
                          fontSize: "10px",
                          opacity: ".5",
                          marginTop: "8px",
                          lineHeight: "16px",
                        }}
                        disableSticky
                      >
                        <T>products</T>
                      </ListSubheader>
                      {productOptions.map((product, idx) => {
                        return (
                          <MenuItem
                            key={idx}
                            value={product.productName}
                            data-test-id={`${product.productName}`}
                          >
                            <Typography variant="h8">
                              {product.productName}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )
            )}
        </Box>
      )}

      {/* Your referral link box */}
      {url && (
        <Box data-test-id="c_referral_link_container">
          <Box
            sx={{
              width: "auto",
              height: "auto",
              backgroundColor: '#E6F5FA',
              borderWidth: "1.9px",
              borderStyle: "dashed",
              borderColor: "#8AD2E9",
              marginTop: '25px',
              borderRadius: '8px',
            }}
            data-test-id="c_referral_link_box"
          >
            <Stack sx={{ padding: "25px 15px 20px 15px" }}>
              <Typography
                variant="h3"
                sx={{ textAlign: "center" }}
                data-test-id="c_referral_link_title"
              >
                <T>your_referral_link</T>
              </Typography>
              {productImage && (
                <Stack
                  sx={{
                    marginTop: "28px",
                    position: 'relative',
                    minHeight: "200px"
                  }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  data-test-id="c_product_image_container"
                >
                  {isProductImageLoading && (
                    <CircularProgress
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1
                      }}
                      data-test-id="c_product_image_loader"
                    />
                  )}
                  <img
                    src={productImage}
                    style={{
                      width: "268px",
                      height: "auto",
                      borderRadius: "8px",
                      opacity: isProductImageLoading ? 0 : 1,
                      transition: 'opacity 0.3s ease-in-out'
                    }}
                    alt="product"
                    onLoad={() => setIsProductImageLoading(false)}
                    onError={() => setIsProductImageLoading(false)}
                    data-test-id="c_product_image"
                  />
                </Stack>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                data-test-id="c_copy_button_container"
              >
                <CopyToClipboard
                  stringToCopy={`${link}`}
                  disabled={isLinkLoading}
                  sendEvent={true}
                  eventName="referral_link_copy"
                  eventLocation="custom_link_section"
                  userType={userInfo.userType}
                  userId={userInfo.unicityId}
                  data-test-id="c_copy_to_clipboard"
                >
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#009ECF",
                      borderRadius: "8px",
                      border: "1px solid rgba(145, 158, 171, 0.20)",
                      padding: "10px",
                      marginTop: "20px",
                      "&:hover": {
                        backgroundColor: "#009ECF",
                        opacity: ".7"
                      },
                      textDecoration: 'none'
                    }}
                  >
                    <img src="icons/white-link.svg" alt="referral_links icon" style={{ marginRight: "5px" }} />
                    <T>copy_link</T>
                  </Button>
                </CopyToClipboard>
              </Stack>
            </Stack>
            {/** Lower Part Stack */}
            <Stack data-test-id="c_link_display_section">
              <Stack sx={{ padding: "0px 15px 20px 15px", position: 'relative' }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: isLinkLoading ? "#e7f5fb" : "#FFFFFF",
                    padding: "16px",
                    width: "auto"
                  }}
                  data-test-id="c_link_text_container"
                >
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      wordBreak: "break-word",
                      color: isLinkLoading ? "#c9d9e2" : "#1f3d64"
                    }}
                    data-test-id="c_link_text"
                  >{link.split("//")[1]}</Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    marginTop: "10px"
                  }}
                  data-test-id="c_toggle_link_container"
                >
                  <Typography
                    onClick={() => toggleLink()}
                    sx={{
                      fontWeight: "400",
                      fontSize: "11px",
                      textAlign: "center",
                      lineHeight: "13.2px",
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: isLinkLoading ? "#c9d9e2" : "#2e536b"
                    }}
                    disabled={isLinkLoading}
                    data-test-id="c_toggle_link_button"
                  >
                    {isLongLink ? <T>switch_to_short_link</T> : <T>switch_to_long_link</T>}
                  </Typography>
                </Stack>
                {
                  isLinkLoading && (
                    <Stack
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center'
                      }}
                      data-test-id="c_link_loading_container"
                    >
                      <CircularProgress
                        sx={{
                          marginTop: "25px",
                          marginRight: "35px"
                        }}
                        data-test-id="c_link_loading_indicator"
                      />
                    </Stack>
                  )
                }
              </Stack>
            </Stack>
          </Box>
          {showUpdateAlert && (
            <ReferralLinksAlert
              color="#153862"
              border="1px solid rgba(0, 158, 207, 0.15)"
              backgroundColor="rgba(0, 158, 207, 0.15)"
              setShowUpdateAlert={setShowUpdateAlert}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};

export { CustomLinkSection };

const paperPropsObj = {
  boxShadow:
    "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
  border: "1px solid rgba(145, 158, 171, 0.1)",
  borderRadius: "8px",
  maxHeight: "500px",
  marginTop: "10px",
};
