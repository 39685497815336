import styled from "styled-components";

const OfficeHorizontalRule = styled.hr.attrs(props => ({
  "data-testid": "office-horizontal-rule"
}))`
    background-color: #EDEFF1;
    height: 1px;
    border: none;
    width: 100%;
    margin: 24px 0px;
`;

export { OfficeHorizontalRule };
