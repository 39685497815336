import mixpanel from "mixpanel-browser";
import { useAuth } from "./useAuth";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../hooks/LanguageContext";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: !process.env.REACT_APP_IS_PRODUCTION,
  track_pageview: false,
  persistence: "localStorage",
});

const useMixPanel = () => {
  const { userInfo } = useAuth();
  const { currentLanguage } = useContext(LanguageContext);
  const { unicityId } = userInfo;

  useEffect(() => {
    if (userInfo && userInfo.unicityId) {
      mixpanel.identify(userInfo.unicityId);

      mixpanel.people.set({
        $country_code: userInfo.countryCode,
        $created: userInfo.joinDate,
        highestRank: userInfo.highestRank,
        totalPartners: userInfo.partnersCount,
        totalOv: userInfo.totalOv,
        totalMembers: userInfo.membersCount,
        language: currentLanguage,
      });
    }
  }, [currentLanguage, unicityId, userInfo]);

  const logEvent = (eventName, eventDetail = {}) => {
    const eventData = { ...eventDetail, language: currentLanguage };
    mixpanel.track(eventName, eventData);
  };

  return { logEvent };
};

export { useMixPanel };
