import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { LANGUAGE_DETAILS } from "../../common/languageDetails";
import { localTranslationTable } from "../../hooks/localTranslationTable";

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    cache: {
      enabled: true,
    },
    // debug: process.env.NODE_ENV === 'development' * enable if needed for help with translations,
    fallbackLng: "en",
    supportedLngs: LANGUAGE_DETAILS.map((language) => language.i18nCode),
    ns: ['translation'],
    defaultNS: 'translation',
    detection: {
      lookupQuerystring: "lang",
      lookupLocalStorage: "i18nextLng",
      order: ["querystring", "localStorage", "navigator"],
      caches: ["localStorage"],
    },
    backend: {
      loadPath: (lngs: string) => {
        // Use the existing translation URL from env
        const fileName = LANGUAGE_DETAILS.find(item => item.i18nCode === lngs[0])?.countryCode;
        return `${process.env.REACT_APP_TRANSLATION_URL}${fileName}.json`;
      },
      parse: (data: string) => {
        try {
          const parsedData = JSON.parse(data);
          // Always merge with local translations to ensure they're available
          return { ...localTranslationTable, ...parsedData };
        } catch (e) {
          console.error('Failed to parse translation data:', e);
          // Return local translations as fallback if parsing fails
          return localTranslationTable;
        }
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false // Disable suspense to prevent loading issues
    }
  });

export default i18n;
