import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { useFormik } from "formik";

import {
  Box,
  Modal,
  Typography,
  Stack,
  FormControl,
  RadioGroup,
  Grid
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { LanguageModalOption } from "./LanguageModalOption";
import { LanguageContext } from "../../hooks/LanguageContext";
import { LANGUAGE_DETAILS } from "../../common/languageDetails";
import { mobileContainerStyles } from "./settings-styles";
import { LanguageModalButtons } from "./LanguageModalButtons";
import { LanguageContextType, LanguageModalProps, LanguageFormValues } from "../../types/components/language";

const LanguageModalMobile: React.FC<LanguageModalProps> = ({ open, handleModalClose, handleClose }) => {
  const { currentLanguage, setCurrentLanguage } = useContext<LanguageContextType>(LanguageContext);

  // Get the countryCode that matches the current i18nCode
  const initialCountryCode = LANGUAGE_DETAILS.find(item => item.i18nCode === currentLanguage)?.countryCode || "en";

  const handleRadioChange = () => {
    formik.setErrors({});
    return formik.handleChange;
  };

  const handleApplyClick = (values: LanguageFormValues) => {
    const i18nCode = LANGUAGE_DETAILS.find(item => item.countryCode === values.selection)?.i18nCode;
    if (i18nCode) {
      setCurrentLanguage(i18nCode);
      handleModalClose();
      handleClose();
    }
  };

  // reset selection when modal closes
  const handleModalCancel = () => {
    handleModalClose();
    formik.setFieldValue("selection", currentLanguage);
  };

  // configure Formik to handle form state
  const formik = useFormik<LanguageFormValues>({
    initialValues: {
      selection: initialCountryCode,
    },
    onSubmit: handleApplyClick,
  });

  return (
    <Modal
      open={open}
      onClose={handleModalCancel}
      aria-labelledby="modal-language-picker"
      aria-describedby="modal-language-picker-menu"
    >
      <form data-test-id={`language-form`} onSubmit={formik.handleSubmit}>
        <Stack sx={mobileContainerStyles} data-test-id="modal-stack">
          <Stack>
            {/* Top section */}
            <Box data-test-id="top-section">
              {/* Header */}
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={1}
                p={3}
                borderBottom="1px solid #EDEFF1"
              >
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "500" }}
                  data-test-id="header-text"
                >
                  <T>change_language</T>
                </Typography>
                <Box data-test-id="close-button-box">
                  <CloseIcon
                    onClick={handleModalCancel}
                    sx={{ cursor: "pointer", height: "16px", width: "16px" }}
                    data-test-id="close-icon"
                  />
                </Box>
              </Stack>
              {/* Radio group */}
              <Stack
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#153862",
                }}
                alignItems={"flex-start"}
                mb={3}
                p={3}
                data-test-id="radio-group-stack"
              >
                <FormControl data-test-id="form-control">
                  <RadioGroup
                    aria-labelledby="language-radio-buttons-group-label"
                    defaultValue="spanish"
                    name="language-radio-group"
                    onChange={handleRadioChange}
                    value={formik.values.selection}
                    data-test-id="radio-group"
                  >
                    <Grid container spacing={0} rowSpacing={0}>
                      {LANGUAGE_DETAILS.map((language, idx) => (
                        <Grid item xs={6} key={idx}>
                          <LanguageModalOption
                            formik={formik}
                            value={language.countryCode}
                            label={language.label}
                            data-test-id={`language-option-${language.countryCode}`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Box>
            {/* Button section */}
            <Box data-test-id="button-section">
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                gap={2}
                p={3}
                borderTop="1px solid #EDEFF1"
              >
                <LanguageModalButtons handleModalCancel={handleModalCancel} />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
};

export { LanguageModalMobile }; 