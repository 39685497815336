import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../hooks/useAuth";
import { BusinessNavItem } from "./BusinessNavItem";
import { ResourceNavItem } from "./ResourceNavItem";
import { businessDrawerItems, resourceDrawerItems } from "./nav-drawer-items";
import { CopyrightSection } from "../CopyrightSection";
import { fgExcludedResourceLinks, fgMarkets } from "../../common/marketExceptions";

const MobileNavDrawer = ({ drawerOpen, toggleDrawer, shopLink }) => {
  const { userInfo } = useAuth();
  const [drawerWidth, setDrawerWidth] = useState(240);

  const subheaderStyle = {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 10,
    color: "#8A9BB0",
    mb: 2,
    mt: 2,
    ml: 1,
  };

  return (
    <div>
      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        <Box
          id="nav-drawer"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: drawerWidth,
            height: "100vh",
            pl: "16px",
            pr: "16px",
            pt: "26px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                mb: "42px",
                alignItems: "center",
              }}
            >
              <Box
                onClick={toggleDrawer}
                aria-label="close navigation"
                sx={{ cursor: "pointer" }}
              >
                <img src="icons/close-icon.svg" alt="close-icon" />
              </Box>
              <Box sx={{ display: "flex", gap: "6.5px" }}>
                <img
                  alt="Unicity Logo"
                  src="icons/logo-unicity.svg"
                  style={{ width: "87px" }}
                />
                <img
                  alt="Office Logo"
                  src="icons/logo-office.svg"
                  style={{ width: "45px" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography sx={subheaderStyle}>BUSINESS</Typography>
            </Box>
            <Box>
              {businessDrawerItems.map((item, index) => {
                return (
                  <BusinessNavItem
                    key={index}
                    item={item}
                    drawerOpen={drawerOpen}
                    toggleDrawer={toggleDrawer}
                    isMobile={true}
                  />
                );
              })}
            </Box>
            <Box>
              <Typography sx={subheaderStyle}>RESOURCES</Typography>
            </Box>
            {resourceDrawerItems
              .filter(
                (item) =>
                  !(
                    (!["CA", "US"].includes(userInfo.countryCode) && 
                      item.value === "new-library") ||
                    (fgMarkets.includes(userInfo.countryCode) && 
                      fgExcludedResourceLinks.includes(item.value))
                  )
              )
              .map((item, index) => (
                <ResourceNavItem
                  key={index}
                  item={item}
                  drawerOpen={drawerOpen}
                  isMobile={true}
                  setDrawerWidth={setDrawerWidth}
                  shopLink={shopLink}
                />
              ))}
          </Box>
          <Box>
            <CopyrightSection padding={1.5} />
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export { MobileNavDrawer };
