import React from "react";
import { T } from "../../hooks/useTranslate";
import { Button } from "@mui/material";
import { buttonStyles } from "./settings-styles";

interface LanguageModalButtonsProps {
  handleModalCancel: () => void;
}

const LanguageModalButtons: React.FC<LanguageModalButtonsProps> = ({ handleModalCancel }) => {
  return (
    <>
      <Button
        id="cancel-button"
        data-test-id="language-selection-cancel-button"
        aria-label="cancel filter"
        sx={{
          color: "#153862",
          backgroundColor: "transparent",
          ...buttonStyles,
        }}
        onClick={handleModalCancel}
      >
        <T>cancel</T>
      </Button>
      <Button
        type="submit"
        id="apply-filter-button"
        data-test-id="language-selection-apply-button"
        aria-label="apply filter"
        sx={{
          color: "#FFFFFF",
          backgroundColor: "#009ECF",
          borderRadius: "4px",
          ...buttonStyles,
          "&:hover": {
            backgroundColor: "rgba(0, 158, 198, 0.48)",
          },
        }}
      >
        <T isTitleCase={true}>apply</T>
      </Button>
    </>
  );
};

export { LanguageModalButtons }; 