import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useErrorTracking } from '../../utils/errorTracking';

// Constants
const HEADERS = {
  APPLICATION: 'office.web',
  CONTENT_TYPES: {
    JSON: 'application/json',
    MULTIPART: 'multipart/form-data'
  }
};

const ERROR_MESSAGES = {
  NETWORK: 'Network error. Please check your connection.',
  SESSION_EXPIRED: 'Your session has expired. Please log in again.',
  UNEXPECTED: 'An unexpected error occurred'
};

class ApiError extends Error {
  constructor(type, message, details = {}) {
    super(message);
    this.type = type;
    this.details = details;
  }
}

const useApiWrapper = () => {
  const { showSnackbar } = useSnackbar();
  const { userInfo, logout } = useAuth();
  const { token } = userInfo;
  const { trackError } = useErrorTracking();

  const API_URL_PREFIX = process.env.API_URL;

  const logError = (error, config) => {
    // Redact sensitive data in production
    const sanitizedError = process.env.NODE_ENV === 'production'
      ? {
        url: error.config?.url,
        method: error.config?.method,
        status: error.response?.status
      }
      : {
        url: error.config?.url,
        method: error.config?.method,
        status: error.response?.status,
        error: error.message,
        details: error.response?.data
      };

    console.error('API Error:', sanitizedError);
  };

  const buildHeaders = (config) => {
    const headers = {
      'x-application': HEADERS.APPLICATION
    };

    const headerBuilders = {
      addToken: () => ({ Authorization: `Bearer ${token}` }),
      addContentType: () => ({ 'Content-Type': HEADERS.CONTENT_TYPES.JSON }),
      addContentTypeMedia: () => ({ 'Content-Type': HEADERS.CONTENT_TYPES.MULTIPART }),
      acceptLanguage: () => ({ 'Accept-Language': config.acceptLanguage })
    };

    return Object.entries(headerBuilders).reduce((acc, [key, builder]) => {
      if (config[key]) {
        return { ...acc, ...builder() };
      }
      return acc;
    }, headers);
  };

  const handleError = (error) => {
    // Track the error with our error tracking system
    console.log('error', error);
    trackError(error, {
      userId: userInfo?.id,
      userCountry: userInfo?.country,
      business: {
        feature: error.config?.url?.split('/')[0] || 'unknown',
        process: error.config?.method === 'get' ? 'fetch' : 'update',
        impact: error.response?.status >= 500 ? 'high' : 'medium',
        customerType: userInfo?.type
      }
    });

    logError(error);

    if (error.response) {
      if (error.response.status === 401) {
        showSnackbar(ERROR_MESSAGES.SESSION_EXPIRED, 'error');
        logout();
      } else {
        const errorMessage = error.response.data?.message || ERROR_MESSAGES.UNEXPECTED;
        showSnackbar(errorMessage, 'error');
      }
    } else if (error.request) {
      showSnackbar(ERROR_MESSAGES.NETWORK, 'error');
    } else {
      showSnackbar(ERROR_MESSAGES.UNEXPECTED, 'error');
    }

    throw new ApiError(
      error.response ? 'response' : error.request ? 'network' : 'unknown',
      error.message,
      error.response?.data
    );
  };

  const request = async (config) => {
    try {
      const url = config.addPrefix ? `${API_URL_PREFIX}/${config.url}` : config.url;

      const response = await axios({
        ...config,
        url,
        headers: buildHeaders(config),
      });

      if (config.successMessage) {
        showSnackbar(config.successMessage, 'success');
      }

      return response;
    } catch (error) {
      if (!axios.isCancel(error)) {
        return handleError(error);
      }
    }
  };

  // Common request configuration
  const defaultConfig = {
    addPrefix: true,
    addToken: true,
    addContentType: true
  };

  return {
    get: (url, config = {}) => request({ method: 'GET', url, ...defaultConfig, addContentType: false, ...config }),
    post: (url, data, config = {}) => request({ method: 'POST', url, data, ...defaultConfig, ...config }),
    put: (url, data, config = {}) => request({ method: 'PUT', url, data, ...defaultConfig, ...config }),
    delete: (url, config = {}) => request({ method: 'DELETE', url, ...defaultConfig, addContentType: false, ...config }),
    patch: (url, data, config = {}) => request({ method: 'PATCH', url, data, ...defaultConfig, ...config }),
    head: (url, config = {}) => request({ method: 'HEAD', url, ...defaultConfig, addContentType: false, ...config })
  };
};

export default useApiWrapper;
