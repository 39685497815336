// Markets that do not have custom link section
const marketsWithNoCustomReferralLinks = [
  "AU",
  "KH",
  "ID",
  "JP",
  "KR", 
  "LA",
  "MM", 
  "NZ",
  "TH",
  "VN",
  "DZ",
  'BS', 
  'LB'
]

export default marketsWithNoCustomReferralLinks
