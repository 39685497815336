import { AxiosError } from 'axios';
import { useMixPanel } from '../hooks/useMixPanel';

interface ErrorResponse {
  code?: string;
  message?: string;
}

export interface CoreErrorTracking {
  code: string;
  message: string;
  timestamp: string;
  endpoint: string;
  method: string;
  statusCode?: number;
  userId?: string;
  userCountry?: string;
  environment: string;
  browserInfo: string;
  errorStack?: string;
  requestData?: unknown;
  responseData?: unknown;
  business?: {
    feature?: string;
    process?: string;
    impact?: 'low' | 'medium' | 'high' | 'critical';
    customerType?: string;
  };
}

const sanitizeData = (data: unknown): unknown => {
  if (!data) return undefined;

  // Add your sanitization logic here
  // Remove sensitive fields like passwords, tokens, etc.
  return data;
};

export const useErrorTracking = () => {
  const { logEvent } = useMixPanel();

  const trackError = (error: AxiosError | Error, additionalContext?: Partial<CoreErrorTracking>): CoreErrorTracking => {
    const isAxiosError = (error as AxiosError).config !== undefined;
    const responseData = (error as AxiosError).response?.data as ErrorResponse;

    const errorData: CoreErrorTracking = {
      // Basic Error Info
      code: isAxiosError ? responseData?.code || 'UNKNOWN_ERROR' : 'UNKNOWN_ERROR',
      message: error.message || 'An unknown error occurred',
      timestamp: new Date().toISOString(),

      // Request Context
      endpoint: isAxiosError ? (error as AxiosError).config?.url || 'UNKNOWN_ENDPOINT' : 'UNKNOWN_ENDPOINT',
      method: isAxiosError ? (error as AxiosError).config?.method?.toUpperCase() || 'UNKNOWN_METHOD' : 'UNKNOWN_METHOD',
      statusCode: isAxiosError ? (error as AxiosError).response?.status : undefined,

      // Environment Info
      environment: process.env.NODE_ENV || 'development',
      browserInfo: navigator.userAgent,

      // Error Details
      errorStack: process.env.NODE_ENV === 'development' ? error.stack : undefined,
      requestData: isAxiosError ? sanitizeData((error as AxiosError).config?.data) : undefined,
      responseData: isAxiosError ? sanitizeData((error as AxiosError).response?.data) : undefined,

      ...additionalContext
    };

    // Track in Mixpanel using the hook
    logEvent('Error Occurred', {
      distinct_id: errorData.userId,
      error_code: errorData.code,
      error_message: errorData.message,
      endpoint: errorData.endpoint,
      method: errorData.method,
      status_code: errorData.statusCode,
      environment: errorData.environment,
      business_feature: errorData.business?.feature,
      business_process: errorData.business?.process,
      business_impact: errorData.business?.impact,
      business_customer_type: errorData.business?.customerType,
      timestamp: errorData.timestamp
    });

    return errorData;
  };

  return { trackError };
};

export const ERROR_CODES = {
  LOGIN_INCORRECT: 'login_or_password_is_incorrect',
  GENERIC_ERROR: 'generic_error',
  PROFILE_INFO_ERROR: 'full_profile_information_error',
  FILE_UPLOAD_ERROR: 'error_5006',
  ADDRESS_VALIDATION_ERROR: 'error_validate_update_address',
  EMAIL_INCORRECT: 'email_id_is_not_correct',
  INSUFFICIENT_CODE_LENGTH: 'insufficient_code_length_error',
  CODE_CHANGE_LIMIT: 'allowed_code_change_count_exceeded_error',
  RESTRICTED_CODE: 'restricted_code_error',
  CODE_ALREADY_ASSIGNED: 'referralcode_already_assigned_error',
  CODE_MAX_LENGTH: 'referral_code_max_length_error',
  REAUTHENTICATION_FAILED: 'failed_to_reauthenticate',
  CODE_DISALLOWED: 'referral_code_disallowed_phrase',
  CODE_INCLUDES_DISALLOWED: 'referral_code_includes_disallow_phrase',
  INVALID_UID: 'invalid_uid',
  NO_NEWS: 'no_news_error',
  INVALID_SSN: 'invalid_ssn_tin',
  SSN_NOT_SAVED: 'ssn_not_saved',
  TOO_MANY_REQUESTS: 'too_many_requests',
  SUSPENDED_ACCOUNT: 'suspended_terminated_error',
  SUCCESS_TRACKER_ERROR: 'success_tracker_error',
  INVALID_CHARACTERS: 'referral_code_invalid_characters'
} as const; 