import React from "react"
import { Box } from "@mui/material"

const OrderDetailItem = ({ item, CurrencyLocalizer }) => {
  const title = item.catalogSlide.content.description;
  const skuNumber = item.item.id.unicity;
  const quantity = item.quantity;
  const priceEach = item.terms.priceEach;
  const pvEach = item.terms.pvEach;
  const totalPrice = priceEach * quantity;
  const totalPV = pvEach * quantity;
  const productThumbnail = item.item?.product_thumbnail;

  return (
    <Box sx={{ marginBottom: "33px" }} data-test-id="c_order_detail_item">
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Box sx={{ display: "flex", gap: "16px", flex: 1 }}>
          {productThumbnail && (
            <Box
              component="img"
              src={productThumbnail}
              alt={title}
              data-test-id="c_order_detail_item_thumbnail"
              sx={{
                width: "60px",
                height: "60px",
                objectFit: "cover",
                borderRadius: "4px",
              }}
            />
          )}
          <Box data-test-id="c_order_detail_item_info">
            <Box sx={{ fontSize: "14px", fontWeight: "600", maxWidth: "270px", marginTop: productThumbnail ? "10px" : "0px" }} data-test-id="c_order_detail_item_title">
              {title}
            </Box>
            <Box sx={{ fontWeight: "400", fontSize: "12px", opacity: ".6" }} data-test-id="c_order_detail_item_subtitle">
              {`SKU: ${skuNumber} • QTY: ${quantity}`}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "16px", marginTop: productThumbnail ? "11px" : "0px" }} data-test-id="c_order_detail_item_price_container">
          <Box sx={{ textAlign: "right", fontWeight: "600", fontSize: "12px" }} data-test-id="c_order_detail_item_total_price">
            {`${CurrencyLocalizer.format(totalPrice)}`}
          </Box>
          <Box sx={{ textAlign: "right", fontWeight: "400", fontSize: "12px", opacity: ".6" }} data-test-id="c_order_detail_item_pv">
            {`${totalPV} PV`}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { OrderDetailItem }