import { useState } from "react";
import { T } from "../../../hooks/useTranslate";
import { translations } from "../../../common/rank-qual-functions";

import { Tooltip, Typography, ClickAwayListener } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const RankQualAlertIcon = ({ type, values, isQualified, translationKeyProp }) => {
  const [open, setOpen] = useState(false);
  const iconUrl = isQualified ? "icons/exclamation-green.svg" : "icons/exclamation-red.svg";
  const iconAlt = isQualified ? "green alert icon" : "red alert icon";

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  // determine which translation key to use
  const translationKey = translationKeyProp ? translationKeyProp :
    isQualified ? translations[type].qualified : translations[type].unqualified;

  // determine if a header is needed
  const header = type === "active_legs" ? "active_legs" : undefined;


  // hoverable tooltip for wider screens
  if (viewportIsSmall) return (
    <Tooltip
      arrow
      placement="top"
      PopperProps={tooltipPopperProps}
      slotProps={tooltipSlotProps}
      title={<TooltipText translationKey={translationKey} values={values} header={header} />}>
      <img src={iconUrl} alt={iconAlt} />
    </Tooltip>
  )

  // clickable tooltip for smaller screens
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        placement="top"
        PopperProps={tooltipPopperProps}
        slotProps={tooltipSlotProps}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<TooltipText translationKey={translationKey} values={values} header={header} />}
      >
        <img src={iconUrl} alt={iconAlt} onClick={handleTooltipOpen} />
      </Tooltip>
    </ClickAwayListener>
  )
}

export { RankQualAlertIcon }


// reusable styled text for tooltip
const TooltipText = ({ translationKey, values, header }) => {
  return <>
    {
      header && <Typography
        sx={{
          color: "#FFF",
          fontSize: "9px",
          fontWeight: "700"
        }}>
        <T>{header}</T>
      </Typography>
    }
    <Typography
      sx={{
        color: "#FFF",
        fontSize: "9px",
        width: "90px"
      }}>
      <T param1={values && values.requiredValue}>{translationKey}</T>
    </Typography>
  </>
}

const tooltipSlotProps = {
  arrow: {
    sx: {
      color: "#566E81"
    }
  },
  tooltip: {
    sx: {
      backgroundColor: "#566E81",
      textAlign: "center"
    }
  }
}

const tooltipPopperProps = {
  disablePortal: true,
}