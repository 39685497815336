import React from "react";
import ReactDOM from "react-dom/client";
import { OfficeRouter } from "./routes/OfficeRouter";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material/styles";
import { createDefaultTheme } from "./themes/createDefaultTheme";
import { AuthProvider } from "./hooks/useAuth";
import { ActivityFeedContextProvider } from "./hooks/ActivityFeedContext";
import { CenturionContextProvider } from "./hooks/CenturionContext";
import { PartnersContextProvider } from "./hooks/PartnersContext";
import { MemberContextProvider } from "./hooks/MemberContext";
import { ActionHubContextProvider } from "./contexts/ActionHubContext";
import { ActionableItemsProvider } from "./hooks/ActionableItemsContext";
import { DistributorContextProvider } from "./hooks/DistributorContext";
import { LanguageContextProvider } from "./hooks/LanguageContext";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import "./index.css";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { LanguageDirectionWrapper } from "./hooks/LanguageDirectionWrapper";
import { FeedbackContextProvider } from "./hooks/FeedbackContext";
import { SnackbarProvider } from './contexts/SnackbarContext';
import VersionChangeBanner from "./components/VersionChangeBanner";
import './services/translation/i18n';


LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY ?? "");

const theme = createDefaultTheme();

function OfficeApp() {
  return (
    <React.StrictMode>
      <SnackbarProvider>
        <LanguageContextProvider>
          <LanguageDirectionWrapper>
            <AuthProvider>
              <MemberContextProvider>
                <DistributorContextProvider>
                  <PartnersContextProvider>
                    <ActionHubContextProvider>
                      <ActionableItemsProvider>
                        <CenturionContextProvider>
                          <ActivityFeedContextProvider>
                            <HelmetProvider>
                              <FeedbackContextProvider>
                                <ThemeProvider theme={theme}>
                                  <VersionChangeBanner />
                                  <OfficeRouter />
                                </ThemeProvider>
                              </FeedbackContextProvider>
                            </HelmetProvider>
                          </ActivityFeedContextProvider>
                        </CenturionContextProvider>
                      </ActionableItemsProvider>
                    </ActionHubContextProvider>
                  </PartnersContextProvider>
                </DistributorContextProvider>
              </MemberContextProvider>
            </AuthProvider>
          </LanguageDirectionWrapper>
        </LanguageContextProvider>
      </SnackbarProvider>
    </React.StrictMode>
  );
}

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY ?? "",
  context: {
    kind: "user",
    key: process.env.REACT_APP_LAUNCHDARKLY_USER ?? "",
  },
  options: {
    /* ... */
  },
})(OfficeApp);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<LDProvider />);
