import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { toTitleCase, toUpperCase } from "../common/format-functions";
import { TranslateProps, TranslateMonthProps } from "../types/components/language";

const T = ({ children, isUpperCase, isTitleCase, components, ...params }: TranslateProps): React.ReactNode => {
  const { ready } = useTranslation();

  if (!ready) return null;

  return (
    <Trans
      i18nKey={children}
      values={params}
      defaults={children}
      components={components}
      tOptions={{ ...params }}
    >
      {children}
    </Trans>
  );
};

const TM = ({ children }: TranslateMonthProps): React.ReactNode => {
  const { t, ready } = useTranslation();
  
  if (!ready) return null;

  let dateSplit = children.split(" ");
  const translatedMonth = t(dateSplit[0].toLowerCase(), { defaultValue: dateSplit[0] }) as string;
  
  return <>{`${translatedMonth} ${dateSplit[1]}`}</>;
};

const TElement = ({ children, isUpperCase, isTitleCase, ...params }: TranslateProps): React.ReactNode => {
  const { t, ready } = useTranslation();

  if (!ready) return null;

  let translatedString = t(children, { ...params, defaultValue: children }) as string;

  if (isUpperCase && translatedString) translatedString = toUpperCase(translatedString);
  if (isTitleCase && translatedString) translatedString = toTitleCase(translatedString);

  if (!translatedString) return <>{`{{${children}}}`}</>;

  const parts = translatedString.split(/\{(\d+)\}/);

  return (
    <>
      {parts.map((part, index) => {
        if (index % 2 === 0) return <React.Fragment key={`text-${index}`}>{part}</React.Fragment>;
        const paramValue = params[`param${part}`] || `{${part}}`;
        return <React.Fragment key={`param-${index}`}>{paramValue}</React.Fragment>;
      })}
    </>
  );
};

const useTranslateKey = (key: string, ...params: any[]): string => {
  const { t, ready } = useTranslation();
  if (!ready) return key;
  return t(key, { ...params, defaultValue: key }) as string;
};

export { T, TM, TElement, useTranslateKey }; 