import React, { useState, createContext, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGE_DETAILS } from "../common/languageDetails";
import { LanguageContextType } from "../types/components/language";

const LanguageContext = createContext<LanguageContextType>({
  currentLanguage: "en",
  setCurrentLanguage: () => {},
  languageDir: "ltr",
  setLanguageDir: () => {},
  isLoading: false,
  localizeNumber: () => ""
});

interface LanguageContextProviderProps {
  children: React.ReactNode;
}

const LanguageContextProvider: React.FC<LanguageContextProviderProps> = ({ children }) => {
  const { i18n } = useTranslation();
  const cachedLanguage = localStorage.getItem('i18nextLng');
  
  // Find the matching language detail using i18nCode instead of countryCode
  const cachedLanguageIsSupported = LANGUAGE_DETAILS.some(
    lang => lang.i18nCode === cachedLanguage
  );
  
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    cachedLanguageIsSupported ? cachedLanguage! : "en"
  );
  const [languageDir, setLanguageDir] = useState<"ltr" | "rtl">("ltr");
  const [isLoading, setIsLoading] = useState(true);

  function localizeNumber(num: number): string {
    const formatted = new Intl.NumberFormat(currentLanguage).format(num);
    return formatted;
  }

  useEffect(() => {
    const loadTranslations = async () => {
      setIsLoading(true);
      try {
        await i18n.changeLanguage(currentLanguage);
        localStorage.setItem('i18nextLng', currentLanguage);
      } catch (error) {
        console.error('Failed to load translations:', error);
        // Fallback to English if translation loading fails
        await i18n.changeLanguage('en');
        localStorage.setItem('i18nextLng', 'en');
      } finally {
        setIsLoading(false);
      }
    };

    loadTranslations();
  }, [currentLanguage, i18n]);

  useEffect(() => {
    document.dir = languageDir;
  }, [languageDir]);

  useEffect(() => {
    if (currentLanguage === "ar") {
      setLanguageDir("rtl");
    } else {
      setLanguageDir("ltr");
    }
  }, [currentLanguage]);

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        setCurrentLanguage,
        languageDir,
        setLanguageDir,
        isLoading,
        localizeNumber
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguageContext = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguageContext must be used within a LanguageContextProvider');
  }
  return context;
};

export { LanguageContext, LanguageContextProvider, useLanguageContext }; 