export const fgMarketOptions = {
  website: [
    {
      id: 1,
      alias: "Unicity Referral Links",
      pages: [
        {
          name: "uShop",
          url: process.env.REACT_APP_IS_PRODUCTION === "true"
            ? process.env.REACT_APP_UNICITY_SHOP_URL_PROD
            : process.env.REACT_APP_UNICITY_SHOP_URL_UAT
        },
        {
          name: "uEnroll",
          url: process.env.REACT_APP_IS_PRODUCTION === "true"
            ? process.env.REACT_APP_UENROLL_PROD
            : process.env.REACT_APP_UENROLL_UAT
        }
      ]
    },
    {
      id: 2,
      alias: "Feel Great Referral Links",
      pages: [
        {
          name: "Homepage",
          url: process.env.REACT_APP_IS_PRODUCTION === "true"
            ? process.env.REACT_APP_FG_URL_PROD
            : process.env.REACT_APP_FG_URL_UAT
        },
        {
          name: "Products Page",
          url: process.env.REACT_APP_IS_PRODUCTION === "true"
            ? process.env.REACT_APP_FG_URL_PROD
            : process.env.REACT_APP_FG_URL_UAT
        }
      ]
    }
  ]
}