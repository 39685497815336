import React from "react";

import { Stack } from "@mui/material";
import { SuspendedChip } from "./SuspendedChip";
import { InactiveChip } from "./InactiveChip";
import { MemberChip } from "./MemberChip";
import Chip from "components/Chip";

// TODO - Make use of the global badge component instead of the individual badges
// THIS IS FOR WEB VIEW ONLY
const ChipContainer = ({
  viewportIsLarge,
  customerType,
  member,
  leg1UnicityId,
  leg2UnicityId
}) => {
  return (
    <Stack
      direction={viewportIsLarge ? "row" : "column"}
      gap={.5}
    >
      {
        member?.unicityId?.trim() === leg1UnicityId?.trim()  && (
          <>
            <Chip
              translationKey="leg"
              customTextStyles={{
                color: "#43C06F"
              }}
              customChipStyles={{
                padding: "1px 6px",
                background: "rgba(67, 192, 111, 0.16)"
              }}
            >
               &nbsp;1
            </Chip>
          </>
        )
      }
      {
          member?.unicityId?.trim() === leg2UnicityId?.trim() && (
          <Chip  
            translationKey="leg"
            customTextStyles={{
              color: "#009ECF"
            }}
            customChipStyles={{
              padding: "1px 6px",
              background: "rgba(0, 158, 207, 0.16)"
            }}
          >
            &nbsp;2
          </Chip>
        )
      }
      {
        customerType === "member" && (
          <MemberChip />
        )
      }
      {
        member.customerStatus === "Suspended" && (
          <SuspendedChip />
        )
      }
      {
        // if a partner, check inactive status by is100PvActive, if a member, check by lastOrderDate
        (member.customerStatus !== "Suspended" && ((member.currentRank !== null && member.currentRank < 2) && (member.previousPaidRank !== null && member.previousPaidRank < 2))) && (
          <InactiveChip />
        )
      }
    </Stack>
  )
}

const TopChips = ({ member, leg1UnicityId, leg2UnicityId }) => {
  return (
    <Stack
      direction="row"
      gap={.5}
      sx={{ marginLeft: "5px" }}
    >
      {
        member?.unicityId?.trim() === leg1UnicityId?.trim()  && (
          <Chip
            translationKey="leg"
            customTextStyles={{
              color: "#43C06F"
            }}
            customChipStyles={{
              padding: "1px 6px",
              background: "rgba(67, 192, 111, 0.16)"
            }}
          >
            &nbsp;1
          </Chip>
        )
      }
      {
          member?.unicityId?.trim() === leg2UnicityId?.trim() && (
            <Chip  
              translationKey="leg"
              customTextStyles={{
                color: "#009ECF"
              }}
              customChipStyles={{
                padding: "1px 6px",
                background: "rgba(0, 158, 207, 0.16)"
              }}
            >
              &nbsp;2
            </Chip>
        )
      }
    </Stack>
  )
}

//  CHIPS NEXT TO THE UNICITY ID
const BottomChips = ({ member, customerType }) => {
  return (
    <Stack
      direction="row"
      gap={.5}
      sx={{ marginLeft: "5px" }}
    >
      {
        customerType === "member" && (
          <MemberChip />
        )
      }
      {
        member.customerStatus === "Suspended" && (
          <SuspendedChip />
        )
      }
      {
        (member.customerStatus !== "Suspended" && ((member.currentRank !== null && member.currentRank < 2) && (member.previousPaidRank !== null && member.previousPaidRank < 2))) && (
          <InactiveChip />
        )
      }
    </Stack>
  )
}

export { ChipContainer, BottomChips, TopChips }