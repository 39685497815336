import React from 'react';
import { styled } from '@mui/material/styles';
import { Badge, Avatar } from '@mui/material';

import { FIRST_INITIAL_COLORS, DEFAULT_AVATAR_COLOR } from '../common/get-colors';


const getColorForInitial = (initial) => {
  return FIRST_INITIAL_COLORS[initial?.toLowerCase()] || DEFAULT_AVATAR_COLOR;
};

const OfficeAvatar = ({
  id,
  imageUrl,
  width = 25,
  height = 25,
  firstInitial,
  addBadge = false,
  badgeOn = false,
  showBorder = false,
  borderColor
}) => {
  const StyledBadge = styled(Badge)(({ theme, borderColor }) => {
    return {
      '& .MuiBadge-badge': {
        backgroundColor: badgeOn ? "#44b700" : "#BEC3C9",
        color: badgeOn ? "#44b700" : "#BEC3C9",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      },
    }
  });

  const MainAvatar = () => {
    return <Avatar
      aria-label="User avatar showing profile image or first letter of first name"
      id={id}
      src={imageUrl}
      sx={{
        width: width,
        height: height,
        backgroundColor: getColorForInitial(firstInitial),
        color: "rgba(21, 56, 98, 1)",
        fontWeight: 600,
        ...(width <= 25 && { fontSize: "11px" }),
        border: showBorder && "2px solid white",
        outline: showBorder && `2px solid ${borderColor}`
      }}
    >
      {firstInitial?.toUpperCase()}
    </Avatar>
  }

  if (!addBadge)
    return (
      <MainAvatar />
    );

  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant="dot"
    >
      <MainAvatar />
    </StyledBadge>
  )
};

export { OfficeAvatar };
