import { useState, useEffect, useContext } from "react";
import { useTranslateKey } from "../../hooks/useTranslate";
import { DateTime } from "luxon";

import { Box, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { styles } from "../../themes/datagrid.styles";
import { formatDateByLocale } from "../../common/date-functions";
import { LanguageContext } from "../../hooks/LanguageContext";
import { getFilterButtonStyling } from "../../screens/PartnersScreen/getFilterButtonStyling";
import { useMixPanel } from "../../hooks/useMixPanel";

// Reusable Filter button accepts button text and menu to be used as props
const FilterButton = ({
  filterModel,
  setFilterModel,
  defaultButtonText,
  filterModelKey,
  apiInProgress,
  MenuComponent,
  handlePageChange
}) => {
  const [buttonText, setButtonText] = useState(defaultButtonText);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isFilterApplied = buttonText !== defaultButtonText;

  const translatedButtonText = useTranslateKey(buttonText);
  const { currentLanguage } = useContext(LanguageContext);
  const currentLocale = currentLanguage.split("-")[0];
  const { logEvent } = useMixPanel();

  const filterButtonTemplate = (startDate, endDate) =>
    `${formatDateByLocale(currentLocale, startDate, DateTime.DATE_SHORT)} - ${formatDateByLocale(currentLocale, endDate, DateTime.DATE_SHORT)}`;

  useEffect(() => {
    // if filterModel has a filterModelKey, set buttonText to filterModelKey
    const filterItem = filterModel.find(item => item.hasOwnProperty(filterModelKey));
    if (filterItem) {
      if (filterModelKey === 'filterHasReferral') {
        setButtonText(filterItem[filterModelKey] === 1 ? 'made_referrals' : 'no_referrals');
      } else if (filterModelKey === 'filterHasFeelGreat') {
        setButtonText(filterItem[filterModelKey] === 1 ? 'feel_great' : 'other_products');
      } else if (filterModelKey === 'filterHasSubscription') {
        setButtonText(filterItem[filterModelKey] === 1 ? 'subscribed' : 'not_subscribed');
      } else if (filterModelKey === "purchasePeriodStartDate") {
        let startDate;
        let endDate;
        // look for object with key "purchasePeriodStartDate" and "purchasePeriodEndDate"
        filterModel.forEach(item => {
          if (item.hasOwnProperty("purchasePeriodStartDate")) {
            startDate = item.purchasePeriodStartDate;
          }
          if (item.hasOwnProperty("purchasePeriodEndDate")) {
            endDate = item.purchasePeriodEndDate;
          }
          setButtonText(filterButtonTemplate(startDate, endDate));
        });
      }
    } else {
      setButtonText(defaultButtonText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel])

  const handleFilterButtonClick = (event) => {
    // ignore click if api is in progress
    if (apiInProgress) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handlePageChange(0);
  };

  const handleCloseIconClick = (e) => {
    //  do not bubble up and open menu on icon click
    e.stopPropagation();
    filterModel.forEach(item => {
      if (filterModelKey === "purchasePeriodStartDate") {
        logEvent("members_filter_change", {
          location: "members",
          filterModel: filterModel.filter(obj => !obj.hasOwnProperty("purchasePeriodStartDate") && !obj.hasOwnProperty("purchasePeriodEndDate"))
        });
        setFilterModel(filterModel.filter(obj => !obj.hasOwnProperty("purchasePeriodStartDate") && !obj.hasOwnProperty("purchasePeriodEndDate")));
      } else if (item.hasOwnProperty(filterModelKey)) {
        logEvent("members_filter_change", {
          location: "members",
          filterModel: filterModel.filter(obj => !obj.hasOwnProperty(filterModelKey))
        });
        setFilterModel(filterModel.filter(obj => !obj.hasOwnProperty(filterModelKey)));
      }
    })
  }

  return (
    <>
      <Box
        id={defaultButtonText}
        data-test-id={`${defaultButtonText.split("_").join("-")}-button`}
        style={getFilterButtonStyling(apiInProgress, open, isFilterApplied)}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleFilterButtonClick}
        disabled={apiInProgress}
        sx={{
          ...styles.button,
          opacity: apiInProgress ? ".5" : null,
          paddingRight: isFilterApplied ? ".5rem" : "0.375rem",
          "&:hover": {
            cursor: apiInProgress ? "not-allowed" : "pointer",
            backgroundColor: "#FAFAFA"
          }
        }}>
        {/* Do not apply translations to purchase period when filter is set because it's already a localized date */}
        <div>{defaultButtonText === "purchase_period" && buttonText !== "purchase_period" ? buttonText : translatedButtonText}</div>
        {open && !isFilterApplied ? (
          <KeyboardArrowUpIcon sx={{
            ...styles.keyboardArrowIcon,
            marginLeft: !isFilterApplied && "0.125rem"
          }} />
        ) : isFilterApplied ? (
          <IconButton
            onClick={handleCloseIconClick}
            sx={{
              ...styles.filterButtonIconButton,
              backgroundColor: apiInProgress ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 158, 207, 0.1)"

            }}>
            <CloseIcon
              sx={{
                ...styles.filterButtonCloseIcon,
                opacity: apiInProgress ? ".5" : null,
                color: apiInProgress ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 158, 207, 1)"
              }} />
          </IconButton>
        ) : (
          <KeyboardArrowDownIcon sx={{
            ...styles.keyboardArrowIcon,
            marginLeft: !isFilterApplied && "0.125rem"
          }} />
        )}
      </Box>
      <MenuComponent
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        open={open}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClose={handleClose}
        setButtonText={setButtonText}
        defaultButtonText={defaultButtonText}
      />
    </>
  );
};

export { FilterButton };
