import { useFormik } from 'formik';
import { useApi } from "../../../../hooks/useApi";
import { useAuth } from "../../../../hooks/useAuth";
import { useTranslateKey } from '../../../../hooks/useTranslate';
import { useValidationSchema } from '../../../../components/LoginComponents/ValidationSchemas';
import { useEmailValidationSchema } from '../../../../components/LoginComponents/ValidationSchemas';
import { usePhoneValidationSchema } from '../../../../components/LoginComponents/ValidationSchemas';
import { getCountriesWithCallingCodes } from './countryCodeHelper';

export const useFormikInstances = (setIsModalLoading, setIsReauthenticated, setIsModalOpen, handleCloseModal, setIsUpdated) => {
  const { userInfo, reauthenticate, refreshUserInfo } = useAuth();
  const { sendRequest } = useApi();

  const translateFailedToReauthenticate = useTranslateKey("failed_to_reauthenticate");
  const translateInvalidEmailAddress = useTranslateKey("invalid_email_address");

  const formikReauthentication = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: useValidationSchema(),
    onSubmit: async ({ username, password }) => {
      setIsModalLoading(true);
      try {
        await reauthenticate(username, password)
          .then(result => {
            if (result) {
              setIsReauthenticated(true);
              formikReauthentication.resetForm();
            } else {
              formikReauthentication.setErrors({
                username: translateInvalidEmailAddress,
              });
            }
          })
          .catch(err => {
            console.log('err:', err)
            formikReauthentication.setErrors({
              password: translateFailedToReauthenticate
            });
          });
      } catch (err) {
        console.error(err);
      } finally {
        setIsModalLoading(false);
      }
    },
  });

  const formikNewEmail = useFormik({
    initialValues: {
      email: "",
      emailConfirm: "",
    },
    validationSchema: useEmailValidationSchema(useTranslateKey),
    onSubmit: async ({ email }) => {
      setIsModalLoading(true);
      try {
        await sendRequest({
          method: "POST",
          endpoint: "customers/me",
          addContentType: true,
          data: JSON.stringify(
            {
              email: email,
            }
          ),
        })
          .then(async () => {
            await refreshUserInfo(userInfo.token);
            setIsModalOpen(false);
          }).then(() => {
            setIsUpdated({ open: true, type: "email", value: email });
            formikNewEmail.resetForm();
            handleCloseModal();
          })
          .catch(err => {
            // try to split the error message on a ., otherwise just use err.message
            const errorMessage = err.response?.data?.error?.error_message?.split('.')?.[0] + '.' || err.message;
            formikNewEmail.setErrors({
              email: errorMessage
            });
          })
      } catch (err) {
        console.log('err:', err)
      } finally {
        setIsModalLoading(false);
      }
    },
  });

  const formikNewPhone = useFormik({
    initialValues: {
      phone: "",
      countryCode: getCountriesWithCallingCodes().find(c => c.isoCode === userInfo.countryCode)?.callingCode || '',
    },
    validationSchema: usePhoneValidationSchema(useTranslateKey),
    onSubmit: async ({ phone, countryCode }) => {
      setIsModalLoading(true);
      try {
        await sendRequest({
          method: "POST",
          endpoint: "customers/me",
          addContentType: true,
          data: JSON.stringify(
            {
              homePhone: countryCode + phone,
              mobilePhone: countryCode + phone,
            }
          ),
        })
          .then(async () => {
            await refreshUserInfo(userInfo.token);
            setIsModalOpen(false);
          }).then(() => {
            setIsUpdated({ open: true, type: "phone", value: countryCode + phone });
            formikNewPhone.resetForm();
            handleCloseModal();
          })
          .catch(err => {
            // try to split the error message on a ., otherwise just use err.message
            const errorMessage = err.response?.data?.error?.error_message?.split('.')?.[0] + '.' || err.message;
            formikNewPhone.setErrors({
              email: errorMessage
            });
          })
      } catch (err) {
        console.log('err:', err)
      } finally {
        setIsModalLoading(false);
      }
    },
  });

  return { formikReauthentication, formikNewEmail, formikNewPhone };
};