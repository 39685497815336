import React from "react";
import { Stack, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslateKey } from "../../hooks/useTranslate";
import { useAuth } from "../../hooks/useAuth"
import { statementButtonStyles } from "./EarningsScreen.styles";
import { handleDownloadClick } from "./EarningsScreen.helper";
import { T } from "../../hooks/useTranslate";

const EarningsStatementButton = ({
  currentMonthStatement,
  monthNameTranslated,
  statementTranslated,
  setShowLoadingOverlay,
  isLabelShown = false,
  statementMonth
}) => {
  const { userInfo } = useAuth();
  const disableButton = currentMonthStatement?.href === undefined;

  const infoText = disableButton ? "statement_not_available" : "statement_available";

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  const translateDownload = useTranslateKey("download");

  const handleDownload = handleDownloadClick(currentMonthStatement?.href, userInfo.token, setShowLoadingOverlay);

  return (
    <Stack direction={isMobile ? "column" : "row"} p={3} spacing={"20px"} justifyContent={"space-between"} alignItems={"center"}>
      {isLabelShown ? (
        <Typography sx={{
          /* Blurb or Label Semi Bold */
          fontFamily: "Inter",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "150%", /* 16.5px */
        }}><T param1={statementMonth}>{infoText}</T></Typography>
      ) : <Typography></Typography>}
      <Button
        disabled={disableButton}
        sx={statementButtonStyles}
        onClick={handleDownload}
        fullWidth={isMobile}
      >
        <Typography variant="h4" pr={0.5} color={disableButton ? "rgba(21, 56, 98, 0.5)" : null}>
          {`${translateDownload} ${statementTranslated}`}
        </Typography>
        {disableButton ? (
          <img src="icons/download-disabled.svg" alt="download icon" />
        ) : (
          <img src="icons/download.svg" alt="download icon" />
        )}
      </Button>
    </Stack >
  );
};

export { EarningsStatementButton };
