import React, { useState, useEffect, useCallback } from "react";
import { T } from "../../../hooks/useTranslate";
import { useMixPanel } from "../../../hooks/useMixPanel.js";
import { useAuth } from "../../../hooks/useAuth.js";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ActionDashboardItemContent } from "../ActionDashboardItem/ActionDashboardItemContent.jsx";
import { useApi } from "../../../hooks/useApi";


const SsnEntryActionItem = ({ children }) => {
  const [ hasGovernmentId, setHasGovernmentId ] = useState(true);
  const { sendRequestAsCallback } = useApi();
  const { logEvent } = useMixPanel();
  const { userInfo } = useAuth();
  const navigate = useNavigate();

  const handleClick = (evt) => {
    logEvent("personal_information_ssn_tin", {
      location: "dashboard",
    });
    navigate("/settings/personal-information#ssn-tin");
  };

  const checkHasGovernmentId = useCallback(async () => {
    let hasGovId = false;
    try {
      const personalInfoResponse = await sendRequestAsCallback({
        endpoint: "customers/me?expand=flags"
      });
      hasGovId = !!personalInfoResponse.data.flags.hasGovernmentId;
    } catch (error) {
      hasGovId = true;
    }
    setHasGovernmentId(hasGovId);
  }, [sendRequestAsCallback]);

  useEffect(() => {
    if (userInfo.hasOwnProperty('hasGovernmentId')) {
      setHasGovernmentId(!!userInfo.hasGovernmentId);
    } else {
      checkHasGovernmentId();
    }
  }, [userInfo, setHasGovernmentId, checkHasGovernmentId]);

  return !hasGovernmentId && (userInfo.countryCode === "US" || userInfo.countryCode === "PR") ? (
    <ActionDashboardItemContent severity="error">
      <img src="icons/exclamation-red.svg" alt="notice icon" style={{ height: 20, width: 20 }} />
      <Typography sx={{
        fontSize: "12px",
        fontWeight: "400",
        color: "#153862"
      }}>
        <span style={{ fontWeight: "500" }}>
          <T>go_to_ssn_tin</T>&nbsp;
        </span>
        <strong style={{ fontWeight: "600", whiteSpace: "nowrap" }}>
          <Link href="/settings/personal-information#ssn-tin" onClick={handleClick}>
            <T>go_now_exclamation</T>
          </Link>
        </strong>
      </Typography>
    </ActionDashboardItemContent>
  ) : '';
};

export { SsnEntryActionItem };
