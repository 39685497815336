import React, { useContext, useEffect } from "react";
import { T } from "../../hooks/useTranslate";

import { ActionHubContext } from "../../contexts/ActionHubContext";

import { Box, Typography, CircularProgress, Stack } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { OfficeBox } from "../../components/OfficeBox";
import { OfficePage } from "../../components/OfficePage";
import { MemberContextProvider } from "../../hooks/MemberContext";

import { ActionHubAlert } from "../../components/ActionHubAlert";
import { ActionHubItem } from "./ActionHubItem";
import { ExplainedButton } from "../../components/ExplainedButton";

const ActionHubScreen = () => {
  const {
    feelGreatCount,
    reminderCount,
    partialsCount,
    error,
    loadingStates
  } = useContext(ActionHubContext);

  useEffect(() => {
    console.log('loadingStates', loadingStates);
  }, [loadingStates]);

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <OfficePage pageTitle={"action_hub"}>
      <MemberContextProvider>
        <OfficeBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            padding: "24px",
          }}
        >
          <Typography variant="h3" data-test-id="action-hub-subheader">
            <T>auto_generated_follow_ups</T>
          </Typography>
          {error && (
            <Typography sx={{ color: "red" }} variant="h5">
              <T>generic_error</T>
            </Typography>
          )}
          {feelGreatCount === 0 &&
            reminderCount === 0 &&
            partialsCount === 0 &&
            !error && (
              <ActionHubAlert
                color="#153862"
                backgroundColor="rgba(21, 56, 98, 0.05)"
                border="1px solid rgba(21, 56, 98, 0.15)"
                boldText={"build_your_network"}
                text={"no_further_activities"}
                maxWidth="800px"
              />
            )}
          <Box
            sx={{
              display: "flex",
              flexDirection: !viewportIsSmall && "column",
              gap: "16px",
              justifyContent: "space-around",
            }}
          >
            {loadingStates.feelGreat ? (
              <Stack alignItems="center" m={6}>
                <CircularProgress data-test-id="progress-bar-shown" />
              </Stack>
            ) : (
              <ActionHubItem
                text="new_feel_great_members"
                id="newfeelgreat-item"
                value={feelGreatCount}
                background="rgba(0, 158, 207, 0.1)"
                border="1px solid rgba(0, 158, 207, 0.2)"
                url="newfeelgreat"
                eventName="ah_new_fg_members"
              />
            )}

            {loadingStates.reminders ? (
              <Stack alignItems="center" m={6}>
                <CircularProgress data-test-id="progress-bar-shown" />
              </Stack>
            ) : (
              <ActionHubItem
                text="order_reminders"
                id="reminders-item"
                value={reminderCount}
                background="rgba(221, 128, 206, 0.1)"
                border="1px solid rgba(221, 128, 206, 0.2)"
                url="reminders"
                eventName="ah_order_reminders"
              />
            )}

            {loadingStates.partials ? (
              <Stack alignItems="center" m={6}>
                <CircularProgress data-test-id="progress-bar-shown" />
              </Stack>
            ) : (
              <ActionHubItem
                text="partials"
                id="partials-item"
                value={partialsCount}
                background="rgba(255, 180, 92, 0.10)"
                border="1px solid rgba(255, 180, 92, 0.20)"
                url="partials"
                eventName="ah_partials"
              />
            )}
          </Box>
        </OfficeBox>
        <Box sx={{ display: "flex", justifyContent: "center", margin: "40px" }}>
          <ExplainedButton
            type="actionHub"
            testId="actionhub-explained-button"
            imgUrl="icons/centurion-info.svg"
            altText="explain alert icon"
            buttonText="action_hub_explained"
            urlKey="action_hub_explained_url"
          />
        </Box>
      </MemberContextProvider>
    </OfficePage>
  );
};

export { ActionHubScreen };
