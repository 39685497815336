import { Box } from "@mui/material";
import { OvToNextRankHeaderCell } from "../PartnersDataGridComponents/OvToNextRankHeaderCell";
import { OvToNextRankCell } from "../PartnersDataGridComponents/OvToNextRankCell";
import { PartnersNameCell } from "./PartnersNameCell";
import { TotalOvCell } from "../TotalOvCell";
import { NewPartnersHeadCell } from "./NewPartnersHeadCell";
import { PartnersHeaderCell } from "./PartnersHeaderCell";
import { GenealogyButtonCell } from "./GenealogyButtonCell";

const headerTextStyle = { fontWeight: "600" };

const columns = [
  {
    field: "name",
    headerName: "name",
    flex: 1.25,
    minWidth: 250,
    headerAlign: "left",
    align: "left",
    renderCell: (params) => (
      <PartnersNameCell
        dataGridParams={params}
        params={params.row}
        customerType={params.row.type}
      />
    ),
    renderHeader: () => (
      <PartnersHeaderCell
        text="name"
        testId="name-column-header"
        sortValue="name"
        headerTextStyle={headerTextStyle}
      />
    )
  },
  {
    field: "nextRank",
    headerName: "ov_to_next_rank",
    flex: .5,
    minWidth: 180,
    headerAlign: "right",
    align: "right",
    renderHeader: (params) => (
      <OvToNextRankHeaderCell params={params} />
    ),
    renderCell: (params) => (
      <OvToNextRankCell key={params.id} params={params.row} />
    ),
    disableReorder: true
  },
  {
    field: "newPartners",
    headerName: "new_partners",
    flex: .4,
    minWidth: 150,
    headerAlign: "right",
    align: "right",
    renderHeader: () => <NewPartnersHeadCell />,
    renderCell: (params) => (
      <Box
        data-test-id="new-partners-value"
        data-field="new-partners-value"
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "centurionCount",
    headerName: "centurion",
    flex: .3,
    minWidth: 131,
    headerAlign: "right",
    align: "right",
    renderCell: (params) => (
      <Box
        data-test-id="centurion-value"
        data-field="centurion-value"
      >
        {params.value}
      </Box>
    ),
    renderHeader: () => (
      <PartnersHeaderCell
        text="centurion"
        testId="centurion-count-column-header"
        sortValue="centurionCount"
        headerTextStyle={headerTextStyle}
      />
    )
  },
  {
    field: "pv",
    headerName: "pv",
    width: 70,
    headerAlign: "right",
    align: "right",
    renderHeader: () => (
      <PartnersHeaderCell
        text="pv"
        testId="pv-column-header"
        sortValue="pv"
        headerTextStyle={headerTextStyle}
      />
    ),
    renderCell: (params) => (
      <Box
        data-test-id="pv-value"
        data-field="pv-value"
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "uncompressedTv",
    headerName: "tv",
    width: 70,
    headerAlign: "right",
    align: "right",
    renderHeader: () => (
      <PartnersHeaderCell
        text="tv"
        testId="uncompressedTv-column-header"
        sortValue="uncompressedTv"
        headerTextStyle={headerTextStyle}
      />
    ),
    renderCell: (params) => (
      <Box
        data-test-id="tv-value"
        data-field="tv-value"
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: "totalOv",
    headerName: "total_ov",
    width: 120,
    headerAlign: "right",
    align: "right",
    renderHeader: () => (
      <PartnersHeaderCell
        text="total_ov"
        testId="total-ov-column-header"
        sortValue="totalOv"
        headerTextStyle={headerTextStyle}
      />
    ),
    renderCell: (params) => <TotalOvCell params={params.row} />
  },
  {
    field: "genealogyButton",
    headerName: "",
    sortable: false,
    width: 35,
    headerAlign: "right",
    align: "right",
    renderCell: (params) => (
      <GenealogyButtonCell
        params={params.row}
        customerType={params.row.hasOwnProperty("is100PvActive") ? "partner" : "member"}
      />
    )
  },
  {
    headerName: "relevance",
    field: "relevance",
    hideFromGrid: true
  },
]

export { columns }